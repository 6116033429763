const Loader = props => {
  let { buttontext = "Loading...", className = '', isLoading = false, isbutton = false } = props;
  isLoading = isbutton ? isbutton : isLoading;

  return (
    <> {
      isLoading ? (
        isbutton ?
          <button className={className} color='primary' disabled {...props}>
            {/* <Spinner className='me-6' size='sm' /> */}
            <span>{buttontext}</span>
          </button> :
          <div
            className='flex justify-center align-items-center w-full min-h-screen fixed bg-slate-100'
            style={{
              zIndex: '30',
              opacity: '0.5',
              top: '0',
              left: '0',
              right: '0',
            }}
          >
            <div className="my-auto h-10 w-10 border-t-transparent border-solid animate-spin rounded-full border-black border-4 duration-[500ms,800ms]"></div>
          </div>
      ) : <></>
    }</>
  )
}

export default Loader
