import React, { useState } from "react";

//validation use formik and yup
import { Form, Formik } from "formik";
import CustomInput from "../../components/custom/CustomInput";
import { RegisterSchema } from "../../validation";
import { postLogin, postRegister } from "../../api";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import SuccessModal from "../../components/Modals/SuccessModal";
import FailedModal from "../../components/Modals/FailedModal";
import { setToken } from "../../utils/storage";
import Provinces from "./Provinces.json";
import CustomSelect from "../../components/custom/CustomSelect";
import { capitalizeEachWord } from "../../utils/capitalize";

export default function Register() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const idFromUrl = window.location.href.split("/")[6];
  const [listProvinces, setListProvinces] = useState([]);

  const login = (values) => {
    postLogin(values)
      .then((res) => {
        console.log("post login data", res);
        setToken(res.data.token);
        localStorage.setItem("name", res.data.data.fullName);
        setTimeout(() => {
          history.push("/app/home");
        }, 2500);
      })
      .catch((err) => {
        dispatch({ type: "set", isFailedModal: true });
        console.error(err);
      });
  };
  const onSubmit = async (values) => {
    let data = {
      role_id: 1,
    };

    let validatedData = {
      ...data,
      ...values,
    };

    console.log("ini value dari validasi", validatedData);
    setIsLoading(true);

    postRegister(validatedData)
      .then((res) => {
        console.log("post register data", res);
        setIsLoading(false);
        dispatch({ type: "set", isSuccessModal: true });
        setTimeout(() => {
          login(values);
        }, 2000);
      })
      .catch((err) => {
        dispatch({ type: "set", isFailedModal: true });
        console.error(err);
      });
  };

  useEffect(() => {
    dispatch({ type: "set", isFailedModal: false });
  }, [1]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const dataProvinces = Provinces.map((item, index) => {
      return {
        label: capitalizeEachWord(item.province),
        value: capitalizeEachWord(item.province),
      };
    });
    setListProvinces(dataProvinces);
  }, []);

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center min-h-screen">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-xl rounded-lg bg-white border-2">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-slate-500 text-2xl font-bold">Sign up</h6>
                </div>
                {idFromUrl && (
                  <div className="text-center mb-3">
                    <h6 className="font-bold text-red-600 text-sm">
                      Your email is not registered
                    </h6>
                  </div>
                )}
                <hr className="mt-6 border-b-1 border-slate-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    fullName: "",
                    email: idFromUrl ? idFromUrl : "",
                    company: "",
                    location: "",
                  }}
                  validationSchema={RegisterSchema}
                  onSubmit={onSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="mb-5 xl:mb-7">
                        <CustomInput
                          label="Email"
                          name="email"
                          type="email"
                          placeholder="Email"
                        />
                      </div>
                      <div className="mb-4">
                        <CustomInput
                          label="Full Name"
                          name="fullName"
                          type="text"
                          placeholder="Full Name"
                        />
                      </div>
                      <div className="mb-4">
                        <CustomInput
                          label="Company"
                          name="company"
                          type="text"
                          placeholder="Company"
                        />
                      </div>
                      <div className="mb-12">
                        <CustomSelect
                          label="Provinces"
                          name="location"
                          placeholder="Provinces"
                          options={listProvinces}
                          showLabel={true}
                        />
                      </div>
                      {/* <div className="mb-4">
                        <CustomInput
                          label="City"
                          name="location"
                          type="text"
                          placeholder="City"
                        />
                      </div> */}
                      {isLoading ? (
                        <button
                          type="button"
                          className="grid grid-cols-3 w-full bg-black h-11 rounded-2xl text-sm text-white hover:bg-slate-500 hover:cursor-not-allowed duration-[500ms,800ms]"
                          disabled
                        >
                          <div className="grid-1 justify-self-end my-auto h-5 w-5 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
                          <div className="grid-2 my-auto -mx-1">
                            {" "}
                            Processing...{" "}
                          </div>
                        </button>
                      ) : (
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="justify-items-center w-full px-6 py-3 text-sm tracking-wide text-white capitalize transition-colors duration-300 transform bg-black rounded-2xl"
                        >
                          <span>Register</span>
                        </button>
                      )}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <SuccessModal text={"Register Successful !"} />
        <FailedModal text={"Register Failed !"} />
      </div>
    </>
  );
}
