import moment from "moment";
import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { getOrder, putOrder } from "../../api";
import VideoModal from "../../components/Modals/VideoModal";

import io from "socket.io-client";
import { Link } from "react-router-dom";
// import { getSpknumber } from "../../utils/spkNumber";

import ReactPaginate from "react-paginate";
import NavbarDetail from "../../components/NavbarDetail";
import SuccessModal from "../../components/Modals/SuccessModal";

export default function OrderDashboard() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [listPart, setlistPart] = useState([]);

  const countDifferenceDays = (date1, date2) => {
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    let color = "";
    if (diffDays < 14) {
      color = "border-yellow-600 bg-yellow-600 text-white";
    }
    if (diffDays === 14) {
      color = "border-green-600 bg-green-600 text-white";
    }
    if (diffDays > 14) {
      color = "border-red-600 bg-red-600 text-white";
    }
    return color;
  };
  const [totals, setTotals] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [perpage] = React.useState(10);
  const countPages = totals / perpage;

  const getOrderData = async () => {
    try {
      const dataOrder = await getOrder(
        `all?page=${pageNumber}&perpage=${perpage}`
      );
      console.log(dataOrder);
      setlistPart(dataOrder.data.data);
      setTotals(dataOrder.data.totalData);
    } catch (error) {
      console.log(error);
    }
  };
  const sendApi = (id, data) => {
    let body = {
      user: data.user,
      update: {
        status: "Send",
      },
      spkNumber: data.spk_number,
    };
    putOrder(body, `${id}`)
      .then((res) => {
        console.log("put order data", res);
        dispatch({ type: "set", isVideoModal: true });
        setTimeout(() => {
          dispatch({ type: "set", isVideoModal: false });
          // history.push("/order-tracking/detail/" + id);
          dispatch({ type: "set", isSuccessModal: true });
          setTimeout(() => {
            dispatch({ type: "set", isSuccessModal: false });
            // history.push("/app/home");
          }, 5000);
        }, 10000);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    dispatch({ type: "set", isVideoModal: false });
    // getOrderData();
  }, [1]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getOrderData();
  }, [pageNumber]); // eslint-disable-line react-hooks/exhaustive-deps

  const [socket, setSocket] = useState(null);
  const [second, setSecond] = useState(180);
  const ENDPOINT = `${process.env.REACT_APP_REALTIME_URL_DEV}`;

  useEffect(() => {
    const newSocket = io(ENDPOINT, { withCredentials: false });
    setSocket(newSocket);
    return () => newSocket.close();
  }, [setSocket]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const seconds = second;
    const timer = setInterval(() => {
      if (second > 0) setSecond(seconds - 1);
    }, 1000);

    if (typeof second !== "undefined" && second <= 0) {
      socket.emit("disconnect_request");
    }

    return () => clearInterval(timer);
  }, [second]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        console.log("web socket connected");
      });

      socket.on("disconnect", (msg, cb) => {
        console.log("web socket connected");
      });

      // listening change on database
      socket.on("order:update", (message) => {
        console.log("websocket order:update :", message);
        getOrderData();
      });
    }
  }, [socket]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <NavbarDetail />
      <div className="px-10 py-10 m-0 ">
        <div className="flex flex-row">
          <div class="w-full ">
            <div class="flex flex-col ">
              <div class="overflow-x-auto shadow-md rounded-lg">
                <div class="inline-block min-w-full align-middle ">
                  <div class="overflow-hidden px-10 bg-white dark:bg-slate-700 ">
                    <table class="min-w-full  dark:text-white">
                      <thead class="dark:bg-slate-900">
                        <tr className="dark:bg-slate-700">
                          <th
                            scope="col"
                            class="py-10 px-6 text-xs font-bold tracking-wider text-left  uppercase  "
                          >
                            SPK NUMBER
                          </th>
                          <th
                            scope="col"
                            class="py-10 px-6 text-xs font-bold tracking-wider text-left  uppercase "
                          >
                            CUSTOMER EMAIL
                          </th>
                          <th
                            scope="col"
                            class="py-10 px-6 text-xs font-bold tracking-wider text-left  uppercase "
                          >
                            MODEL
                          </th>
                          <th
                            scope="col"
                            class="py-10 px-6 text-xs font-bold tracking-wider text-left  uppercase "
                          >
                            KATASHIKI
                          </th>
                          <th
                            scope="col"
                            class="py-10 px-6 text-xs font-bold tracking-wider text-left  uppercase "
                          >
                            ORDER TIME
                          </th>
                          <th
                            scope="col"
                            class="py-10 px-6 text-xs font-bold tracking-wider text-left  uppercase "
                          >
                            ESTIMATED DELIVERY TIME
                          </th>

                          <th
                            scope="col"
                            class="py-10 px-6 text-xs font-bold tracking-wider text-left  uppercase "
                          >
                            <span class="py-10 px-6 text-xs font-bold tracking-wider text-left  uppercase ">
                              Action
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="bg-white divide-y divide-gray-200 dark:bg-slate-700 dark:divide-slate-500 dark:text-white">
                        {listPart.map((item, index) => (
                          <tr class="hover:bg-gray-100 dark:hover:bg-slate-400">
                            <td class="py-4 px-6 text-sm font-medium text-gray-900 dark:text-white whitespace-nowrap">
                              <Link to={`/order-tracking/main/${item.id}`}>
                                {/* {getSpknumber(item.id)} */}
                                {item.spk_number}
                              </Link>
                            </td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-900 dark:text-white whitespace-nowrap">
                              {item.user.email}
                            </td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-900 dark:text-white whitespace-nowrap">
                              {`${item.car_model_master.model}`.toUpperCase()}
                            </td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-900 dark:text-white whitespace-nowrap">
                              {`${item.car_model_master.katashiki_code}`.toUpperCase()}
                            </td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-900 dark:text-white whitespace-nowrap">
                              <div className="rounded-3xl px-3 border-2 py-2 text-center">
                                {moment(item.createdAt).format("DD-MM-YY")}
                              </div>
                            </td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-900 dark:text-white whitespace-nowrap b">
                              <div
                                className={`rounded-3xl border-2 py-2 text-center ${countDifferenceDays(
                                  moment(item.createdAt).toDate(),
                                  moment(item.estimated_time).toDate()
                                )}`}
                              >
                                {moment(item.estimated_time).format("DD-MM-YY")}
                              </div>
                            </td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-900 dark:text-white whitespace-nowrap">
                              <button
                                disabled={
                                  item.status === "Not Complete" ? false : true
                                }
                                onClick={() => {
                                  sendApi(item.id, item);
                                }}
                                className={`rounded-3xl py-2 text-center w-10/12 bg-gray-200 ${item.status === "Not Complete" ? "hover:bg-sky-500 hover:text-white":"text-gray-300"}`}
                              >
                                Send
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid place-content-center mt-6">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={countPages}
                onPageChange={(pageSelected) => {
                  setPageNumber(pageSelected.selected + 1);
                }}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </div>
        </div>
        <VideoModal video={"/videos/video-1.mp4"} />
        <SuccessModal text={"Order received, please check your email"} />
      </div>
    </>
  );
}
