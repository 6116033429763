import React, { useEffect, useState } from "react";
import Modal from ".";
import { useDispatch, useSelector } from "react-redux";
import { Dismiss24Filled } from "@fluentui/react-icons";

import nodata from "../../assets/img/nodata.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";
import { noValue } from "../../utils/validateInput";

export default function ProductModal() {
  const dispatch = useDispatch();
  const [listExterior, setListExterior] = useState([]);
  const [listInterior, setListInterior] = useState([]);
  const [listSafety, setListSafety] = useState([]);
  const [indexSwiper, setIndexSwiper] = useState(0);
  const { isProductModal, isExterior, isInterior, carModels } = useSelector(
    (state) => state
  );

  function filterCarModels() {
    var tempModels = carModels
      .filter((carModels) => carModels.type === "exterior")
      .map((filteredModels) => filteredModels);
    setListExterior(tempModels);
    console.log("exterior hungkul", tempModels);
    var tempModels1 = carModels
      .filter((carModels) => carModels.type === "interior")
      .map((filteredModels1) => filteredModels1);
    setListInterior(tempModels1);
    console.log("interior hungkul", tempModels1);
    var tempModels2 = carModels
      .filter((carModels) => carModels.type === "safety")
      .map((filteredModels2) => filteredModels2);
    setListSafety(tempModels2 || []);
    console.log("safety hungkul", tempModels2);
  }

  useEffect(() => {
    if (carModels) {
      filterCarModels();
      console.log("ini car models", carModels);
    }
    dispatch({
      type: "set",
      isProductModal: false,
      isExterior: false,
      isInterior: false,
      isBeyond: false,
    });
  }, [1, carModels]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log("ini index on swipe", indexSwiper);
  }, [indexSwiper]);

  const renderHeaderExterior = () => {
    return listExterior.map((item, index) => {
      return (
        <>
          {index === indexSwiper ? (
            <div key={index} className="font-semibold text-xl">
              {item.title}
            </div>
          ) : (
            <></>
          )}
        </>
      );
    });
  };
  const renderHeaderInterior = () => {
    return listInterior.map((item, index) => {
      return (
        <>
          {index === indexSwiper ? (
            <div key={index} className="font-semibold text-xl">
              {item.title}
            </div>
          ) : (
            <></>
          )}
        </>
      );
    });
  };
  const renderHeaderSafety = () => {
    return listSafety.map((item, index) => {
      return (
        <>
          {index === indexSwiper ? (
            <div key={index} className="font-semibold text-xl">
              {item.title}
            </div>
          ) : (
            <></>
          )}
        </>
      );
    });
  };
  const renderFooterExterior = () => {
    return listExterior.map((item, index) => {
      return (
        <>
          {index === indexSwiper ? (
            <div key={index} className="text-base">
              {item.description}
            </div>
          ) : (
            <></>
          )}
        </>
      );
    });
  };
  const renderFooterInterior = () => {
    return listInterior.map((item, index) => {
      return (
        <>
          {index === indexSwiper ? (
            <div key={index} className="text-base">
              {item.description}
            </div>
          ) : (
            <></>
          )}
        </>
      );
    });
  };
  const renderFooterSafety = () => {
    return listSafety.map((item, index) => {
      return (
        <>
          {index === indexSwiper ? (
            <div key={index} className="text-base">
              {item.description}
            </div>
          ) : (
            <></>
          )}
        </>
      );
    });
  };

  return (
    <>
      {isProductModal ? (
        <Modal>
          <div className="relative my-6 mx-auto w-[76vw] lg:w-[56vw] h-[70vh]">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex flex-row-reverse pr-10 pt-6">
                <div
                  className="cursor-pointer hover:scale-105"
                  onClick={() =>
                    dispatch({
                      type: "set",
                      isProductModal: false,
                      isBeyond: false,
                      isExterior: false,
                      isInterior: false,
                    })
                  }
                >
                  <Dismiss24Filled color="#000" />
                </div>
              </div>
              <div className="pl-10 pb-10 pr-10">
                {isExterior
                  ? renderHeaderExterior()
                  : isInterior
                  ? renderHeaderInterior()
                  : renderHeaderSafety()}
                <section className="h-96 py-7">
                  <Swiper
                    slidesPerView={"1"}
                    spaceBetween={30}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Pagination]}
                    onActiveIndexChange={(e) => setIndexSwiper(e.activeIndex)}
                    className="mySwiper"
                  >
                    {isExterior ? (
                      <>
                        {listExterior.map((item, index) => (
                          <SwiperSlide key={index}>
                            <img
                              src={item.image}
                              className="w-4/5 rounded-lg"
                              loading="lazy"
                              alt="..."
                            ></img>{" "}
                          </SwiperSlide>
                        ))}
                      </>
                    ) : isInterior ? (
                      <>
                        {listInterior.map((item, index) => (
                          <SwiperSlide key={index}>
                            <img
                              src={item.image}
                              className="w-4/5 rounded-lg"
                              loading="lazy"
                              alt="..."
                            ></img>{" "}
                          </SwiperSlide>
                        ))}
                      </>
                    ) : (
                      <>
                        {noValue(listSafety) ? (
                          <SwiperSlide>
                            <img
                              src={nodata}
                              className="w-full rounded-lg"
                              loading="lazy"
                              alt="..."
                            ></img>{" "}
                          </SwiperSlide>
                        ) : (
                          <>
                            {listSafety.map((item, index) => (
                              <SwiperSlide key={index}>
                                <img
                                  src={item.image}
                                  className="w-4/5 rounded-lg"
                                  loading="lazy"
                                  alt="..."
                                ></img>{" "}
                              </SwiperSlide>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </Swiper>
                </section>
                {isExterior
                  ? renderFooterExterior()
                  : isInterior
                  ? renderFooterInterior()
                  : renderFooterSafety()}
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
}
