import React, { useEffect } from "react";
import Modal from ".";
import { useDispatch, useSelector } from "react-redux";

export default function VideoModal({ video }) {
  const dispatch = useDispatch();
  const { isVideoModal } = useSelector((state) => state);

  useEffect(() => {
    dispatch({ type: "set", isVideoModal: false });
  }, [1]); // eslint-disable-line react-hooks/exhaustive-deps

  function closeModal() {
    dispatch({ type: "set", isVideoModal: false });
  }

  return (
    <>
      {isVideoModal ? (
        <Modal closeModal={closeModal}>
          {/* <div className="relative my-6 mx-auto w-[80vw] lg:w-[60vw]"> */}
          <div className="relative mx-auto my-auto w-[90vw] bg-white px-10">
            <video src={video} autoPlay />
          </div>
        </Modal>
      ) : null}
    </>
  );
}
