import React, { useEffect } from "react";
import Modal from ".";
// import { Info24Regular } from "@fluentui/react-icons";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";

export default function ValidationModal(props) {
    const { text, approveButton, cancelButton } = props;

    const dispatch = useDispatch();
    const { isValidationModal } = useSelector((state) => state);

    useEffect(() => {
        dispatch({ type: "set", isValidationModal: false });
    }, [1]); // eslint-disable-line react-hooks/exhaustive-deps

    // function closeModal() {
    //     dispatch({ type: "set", isValidationModal: false });
    // }


    return (
        <>
            {isValidationModal ?
                <Modal>
                    <div className="relative my-6 mx-auto max-w-xl">
                        <div className="border-0 rounded-lg shadow-e3 relative px-12 flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="text-center mt-8 font-bold text-xl">
                                {/* <span className="pr-5">
                                    <Info24Regular color="#1A202C" />
                                </span> */}
                                Are you sure?
                            </div>
                            <div className={`text-center my-20 text-xl font-bold ${text ? "" : "hidden"}`}>
                                {text}
                            </div>
                            <div className={`w-full mb-10 grid justify-items-center ${text ? "" : "mt-10"}`}>
                                <button
                                    type="button"
                                    className=" bg-green-600 w-72 h-9 px-2 py-1 rounded-lg"
                                    onClick={approveButton}
                                >
                                    <span className="text-white font-bold text-base">Yes</span>
                                </button>
                                <div className="pt-3">
                                    <button
                                        type="button"
                                        className=" bg-red-100 w-72 h-9 px-2 py-1 rounded-lg"
                                        onClick={cancelButton}
                                    >
                                        <span className="text-red-600 font-bold text-base">No</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal> : null
            }
        </>
    );
}

ValidationModal.defaultProps = {
    text: '',
    cancelButton: () => { },
    approveButton: () => { },
};

ValidationModal.propTypes = {
    text: PropTypes.string,
    cancelButton: PropTypes.func,
    approveButton: PropTypes.func,
};
