import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { checkpoint, postLogin } from "../../api";
import Footer from "../../components/Footers/Footer";
import SuccessModal from "../../components/Modals/SuccessModal";
import { getToken, setToken } from "../../utils/storage";

export default function OrderCheckpoint() {
  const auth = getToken();
  const [email, setemail] = useState("");
  const idFromUrl = window.location.href.split("/")[6];
  const history = useHistory();

  const dispatch = useDispatch();

  const checkStatus = (key) => {
    switch (key) {
      case "1":
        return "Order Created";
      case "2":
        return "Order Processed";
      case "3":
        return "Car Manufactured";
      case "4":
        return "Car On Delivery";
      case "5":
        return "Order On Process";
      default:
        break;
    }
  };
  return (
    <>
      <section className="bg-white">
        <div className="flex justify-center min-h-screen">
          <div className="flex items-center w-full max-w-3xl lg:w-2/5">
            <div
              className="absolute sm:hidden lg:block lg:h-[18rem] top-0 lg:left-0 h-[18rem] max-h-[18rem] w-full bg-cover bg-no-repeat bg-center rounded-br-3xl rounded-bl-3xl"
              style={{
                backgroundImage:
                  "url(" +
                  require("../../assets/img/bg-order.svg").default +
                  ")",
              }}
            ></div>
            <div className="w-full">
              <div className="p-8 mt-40 lg:mt-24 pb-28 xl:pb-40 mx-auto lg:px-12">
                <h1 className="xl:text-3xl text-2xl font-semibold text-center tracking-wider text-black capitalize mb-5">
                  Order Checkpoint
                </h1>
                <form>
                  {auth === null && (
                    <>
                      <p className="text-center mb-12 xl:mb-24 text-black">
                        Please enter your email
                      </p>
                      <div className="mb-4">
                        <input
                          onChange={(event) => {
                            setemail(event.target.value);
                          }}
                          type="text"
                          placeholder="Booking Number"
                          className="peer block w-full px-5 py-3 mt-2 text-black placeholder-gray-400 bg-gray-100 rounded-2xl border border-gray-200 focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        <p className="mt-2 invisible peer-invalid:visible text-red-600 text-sm">
                          Please provide a valid email address.
                        </p>
                      </div>
                    </>
                  )}
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      const body = {
                        email: email,
                        token: auth,
                        name: checkStatus(`${idFromUrl}`),
                        sort_order: parseInt(idFromUrl),
                        status: "completed",
                      };
                      checkpoint(body)
                        .then((res) => {
                          console.log("post checkpoint data", res);
                          dispatch({ type: "set", isSuccessModal: true });
                          if (!auth) {
                            postLogin({ email: email })
                              .then((res2) => {
                                console.log("post login data", res2);
                                setToken(res2.data.token);
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }
                          setTimeout(() => {
                            dispatch({ type: "set", isSuccessModal: false });
                            history.push(
                              "/order-tracking/detail/" + res.data.data
                            );
                          }, 4000);
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                    className="justify-items-center w-full px-6 py-3 text-sm tracking-wide text-white capitalize transition-colors duration-300 transform bg-black rounded-2xl"
                  >
                    <span>Next</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 w-full">
          <Footer />
        </div>
        <SuccessModal text={"Checkpoint Success !"} />
      </section>
    </>
  );
}
