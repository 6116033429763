import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import ProductModal from "../../components/Modals/ProductModal";
import { getCar } from "../../api";
// import { priceIDR } from "../../store";
import Loader from "../../components/loader";
// import { noValue } from "../../utils/validateInput";

export default function Product() {
  const dispatch = useDispatch();
  const [carDetails, setCarDetails] = useState({});
  const idFromUrl = window.location.href.split("/")[6];
  const [isLoading, setIsLoading] = useState(false);
  // const [idFromUrl, setIdFromUrl] = React.useState(window.location.href.split('/')[6])
  //   const { carId } = useSelector((state) => state);
  // const [lastIndex, setLastIndex] = useState(0);
  const [carImage, setCarImage] = useState("");
  async function getCarsData() {
    setIsLoading(true);
    const dataCars = await getCar(`all?page=1&perpage=5&model=${idFromUrl}`);
    console.log(dataCars);
    // getCar(`${carId ? carId : idFromUrl}`)
    getCar(`${dataCars.data.data[0].id}`)
      .then((res) => {
        var tempList = [];
        tempList = res.data;
        console.log("list cars detail", res);
        setCarDetails(tempList);
        setCarImage(tempList.image);
        dispatch({
          type: "set",
          carDetails: tempList,
          carModels: tempList.car_model_details,
          carColors: tempList.car_model_colors,
          carParts: tempList.car_model_parts,
          carAccessories: tempList.car_model_accessories,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  }

  useEffect(() => {
    // setIdFromUrl(window.location.href.split('/')[6])
    getCarsData();
    dispatch({
      type: "set",
      isExterior: false,
      isInterior: false,
      isBeyond: false,
    });
  }, [1]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <section className="bg-white">
        <div className="flex justify-center min-h-[80vh]">
          <div className="flex items-center w-full max-w-3xl p-8 pb-10 xl:pb-0 mx-auto lg:px-12 lg:w-1/4">
            <div className="w-full">
              <div className="grid justify-items-center pb-14">
                <img src={carDetails.titleImage} className="w-96 " alt="..." />
              </div>
              <div className="text-center tracking-wider text-black capitalize pb-12">
                <div className="xl:text-4xl text-2xl">
                  {carDetails.engine}
                  <small className="text-xs">{carDetails.engine_type}</small>
                </div>
                <div className="pt-5">Engine</div>
              </div>
              <div className="text-center tracking-wider text-black capitalize pb-12">
                <div className="xl:text-4xl text-2xl">
                  {carDetails.power}
                  <small className="text-xs">HP</small>
                </div>
                <div className="pt-5">Power</div>
              </div>
              <div className="text-center tracking-wider text-black capitalize pb-12">
                <div className="xl:text-4xl text-2xl">
                  {carDetails.torque}
                  <small className="text-xs">Nm</small>
                </div>
                <div className="pt-5">Torque</div>
              </div>
              <div className="grid justify-items-center pb-10">
                <button className="justify-items-center w-1/2 px-6 py-3 min-w-max tracking-wide text-gray-800 capitalize transition-colors duration-300 transform bg-gray-50 rounded-full">
                  <div className="justify-items-center grid">
                    <div className="text-xs opacity-75">Starting From</div>
                    <span className="text-base font-semibold">
                      Price Following Market Condition
                      {/* {priceIDR(
                        carDetails.price ? carDetails.price : "Not Exist"
                      )} */}
                    </span>
                  </div>
                </button>
              </div>
              <Link to={`/detail/product/custom/${idFromUrl}`}>
                <button className="justify-items-center w-full px-6 py-3 text-base tracking-wide text-gray-800 capitalize transition-colors duration-300 transform bg-gray-400 rounded-full">
                  <span className="font-semibold">Customize Order</span>
                </button>
              </Link>
            </div>
          </div>
          <div className="hidden bg-cover lg:block lg:w-1/2 w-full bg-gradient-radial">
            <div className="absolute z-10 left-[28%] lg:top-[24%] max-w-[43rem] min-w-[20rem]">
              <img
                src={carImage}
                className="w-full z-0"
                alt="..."
                // onDrag={(e) => {
                //   // console.log(e.pageX, e.screenX * -1);
                //   if (e.pageX !== lastIndex && e.pageX % 20 === 0) {
                //     if (e.pageX > e.screenX * -1) {
                //       console.log("kanan");
                //       setLastIndex(e.pageX);
                //     }
                //     if (e.pageX < e.screenX * -1) {
                //       console.log("kiri");
                //       setLastIndex(e.pageX);
                //     }
                //   }
                // }}
              />
            </div>
            <div className="absolute left-[35%] lg:top-[11%] max-w-[28rem] min-w-[14rem]">
              <img
                src={require("../../assets/img/bg-product.svg").default}
                className="w-full"
                alt="..."
              ></img>{" "}
            </div>
          </div>
          <div className="flex items-center justify-end w-full max-w-2xl min-w-min pb-28 xl:pb-0  mx-auto lg:px-0 lg:py-5 lg:w-1/4">
            <div className="w-11/12">
              <div className="grid grid-rows-3 grid-flow-col gap-5">
                <div
                  className="hidden bg-cover lg:block lg:w-full rounded-3xl rounded-tr-none rounded-br-none cursor-pointer"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("../../assets/img/bg-exterior.svg").default +
                      ")",
                  }}
                  onClick={() =>
                    dispatch({
                      type: "set",
                      isProductModal: true,
                      isExterior: true,
                    })
                  }
                >
                  <div className="grid justify-items-center items-center h-56 text-white">
                    <span className="underline underline-offset-4 text-lg font-medium">
                      Explore Exterior
                    </span>
                  </div>
                </div>
                <div
                  className="hidden bg-cover lg:block lg:w-full rounded-3xl rounded-tr-none rounded-br-none cursor-pointer"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("../../assets/img/bg-interior.svg").default +
                      ")",
                  }}
                  onClick={() =>
                    dispatch({
                      type: "set",
                      isProductModal: true,
                      isInterior: true,
                    })
                  }
                >
                  <div className="grid justify-items-center items-center h-56 text-white">
                    <span className="underline underline-offset-4 text-lg font-medium">
                      Explore Interior
                    </span>
                  </div>
                </div>
                <div
                  className="hidden bg-cover lg:block lg:w-full rounded-3xl rounded-tr-none rounded-br-none cursor-pointer"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("../../assets/img/bg-beyond.svg").default +
                      ")",
                  }}
                  onClick={() =>
                    dispatch({
                      type: "set",
                      isProductModal: true,
                      isBeyond: true,
                    })
                  }
                >
                  <div className="grid justify-items-center items-center h-56 text-white">
                    <span className="underline underline-offset-4 text-lg font-medium">
                      Beyond Safety
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ProductModal />
      <Loader isLoading={isLoading} />
    </>
  );
}
