import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Footer from "../components/Footers/Footer";

// components
import Navbar from "../components/Navbars";

import Home from "../views/main/Home";
// import Product from "../views/main/Product";
// import ProductCustom from "../views/main/ProductCustom";
import ProductionPlan from "../views/main/ProductionPlan";

export default function Main() {
  return (
    <>
      <div className="bg-gray-50 h-full">
        <Navbar />
        <main>
          <section className="relative w-full h-full">
            <div className="top-0 w-full h-full bg-no-repeat bg-full"></div>
            <Switch>
              <Route path="/app/home" exact component={Home} />
              <Route
                path="/app/production-plan"
                exact
                component={ProductionPlan}
              />
              <Redirect from="/" to="/app/home" />
            </Switch>
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
}
