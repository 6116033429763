import moment from "moment";
import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { getOrder, getOrderDetail } from "../../api";

import io from "socket.io-client";
import VideoModal from "../../components/Modals/VideoModal";
// import { useHistory } from "react-router";
import { sourceChanger, sourceColorChanger } from "../../utils/sourceChanger";
import ReactPaginate from "react-paginate";
import NavbarDetail from "../../components/NavbarDetail";
import Footer from "../../components/Footers/Footer";

export default function OrderDetail() {
  // const history = useHistory();

  const dispatch = useDispatch();
  const [listPart, setlistPart] = useState([]);
  const [totalQty, settotalQty] = useState(0);
  const [orderDetail, setorderDetail] = useState({});
  const idFromUrl = window.location.href.split("/")[6];
  const [dataId, setDataId] = useState("");
  const [totals, setTotals] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [perpage] = React.useState(10);
  const countPages = totals / perpage;

  const getOrderData = async (orderId) => {
    try {
      const dataOrder = await getOrder(`${orderId ? orderId : idFromUrl}`);
      const dataOrderDetail = await getOrderDetail(
        `all?page=${pageNumber}&perpage=10&order_id=` +
          (orderId ? orderId : idFromUrl)
      );
      setorderDetail(dataOrder.data);
      settotalQty(dataOrderDetail.data.totalData);
      setlistPart(dataOrderDetail.data.data);
      setTotals(dataOrderDetail.data.totalData);
      setDataId(orderId ? orderId : idFromUrl);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrderData();
  }, [pageNumber]); // eslint-disable-line react-hooks/exhaustive-deps

  const sendApi = (id) => {
    dispatch({ type: "set", isVideoModal: true });
    setTimeout(() => {
      dispatch({ type: "set", isVideoModal: false });
      socketNeta.emit("led", "v");
    }, 10000);
  };

  useEffect(() => {
    dispatch({ type: "set", isSuccessModal: false });
    getOrderData();
  }, [1]); // eslint-disable-line react-hooks/exhaustive-deps
  const [socket, setSocket] = useState(null);
  const [second, setSecond] = useState(180);
  const ENDPOINT = `${process.env.REACT_APP_REALTIME_URL_DEV}`;

  useEffect(() => {
    const newSocket = io(ENDPOINT, { withCredentials: false });
    setSocket(newSocket);
    return () => newSocket.close();
  }, [setSocket]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const seconds = second;
    const timer = setInterval(() => {
      if (second > 0) setSecond(seconds - 1);
    }, 1000);

    if (typeof second !== "undefined" && second <= 0) {
      socket.emit("disconnect_request");
    }

    return () => clearInterval(timer);
  }, [second]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        console.log("web socket connected");
      });

      socket.on("disconnect", (msg, cb) => {
        console.log("web socket connected");
      });

      // listening change on database
      socket.on("order:update", (message) => {
        console.log("websocket order:update :", message);
        getOrderData(message.id);
      });
    }
  }, [socket]); // eslint-disable-line react-hooks/exhaustive-deps

  const [socketNeta, setSocketNeta] = useState(null);
  const [secondNeta, setSecondNeta] = useState(180);
  const ENDPOINTNeta = `http://172.16.0.21:38081`;

  useEffect(() => {
    const newSocket = io(ENDPOINTNeta, { withCredentials: false });
    setSocketNeta(newSocket);
    return () => newSocket.close();
  }, [setSocketNeta]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const seconds = secondNeta;
    const timer = setInterval(() => {
      if (secondNeta > 0) setSecondNeta(seconds - 1);
    }, 1000);

    if (typeof secondNeta !== "undefined" && secondNeta <= 0) {
      socketNeta.emit("disconnect_request");
    }

    return () => clearInterval(timer);
  }, [secondNeta]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (socketNeta) {
      socketNeta.on("connect", () => {
        console.log("web socket neta connected");
      });

      socketNeta.on("disconnect", (msg, cb) => {
        console.log("web socket neta connected");
      });

      // listening change on database
      socketNeta.on("order:update", (message) => {
        console.log("websocket neta order:update :", message);
      });
    }
  }, [socketNeta]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <NavbarDetail />
      <div
        className={`py-10 m-0 dark:bg-slate-900 transition-colors duration-300 transform min-h-[92vh]`}
      >
        <div className="flex flex-row align-middle content-center items-center justify-center my-auto pl-10">
          <div className="basis-1 md:basis-2/4 ">
            <div className="flex flex-col text-center ">
              <div
                className="w-3/4 mx-auto flex items-center my-auto h-[40vh]"
                style={{
                  backgroundImage: 'url("./bg-order-detail.png")',
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "70% 100%",
                  backgroundPosition: "center",
                }}
              >
                <img src={orderDetail.car_model_color?.image} alt="Fortuner" />
              </div>
              <span className="font-semibold text-xl mt-10 dark:text-white">
                {orderDetail.car_model_master?.name}
              </span>
              <span className="dark:text-white">
                {orderDetail.car_model_master?.code}
              </span>
              <span className="text-xs mt-10 dark:text-white">
                Katashiki Code
              </span>
              <div className="rounded-3xl mt-2 border-4 flex-warp mx-44 py-2 bg-gray-100 dark:bg-slate-700 dark:text-white">
                {orderDetail.car_model_master?.katashiki_code}
              </div>
              <span className="mt-10 dark:text-white">
                {"Total Part Quantity: " + totalQty + " Part Items"}
              </span>
            </div>
          </div>
          <div className="basis-1 md:basis-3/4 pr-10">
            <div class="w-[65vw]">
              <div class="flex flex-col">
                <div class="overflow-x-auto shadow-md rounded-lg">
                  <div class="inline-block align-middle ">
                    <div class="h-[69vh] overflow-y-scroll px-10 bg-white dark:bg-slate-700 transition-colors duration-300 transform">
                      <button
                        onClick={() => {
                          sendApi(dataId);
                        }}
                        className="rounded-3xl py-2 text-center w-full mt-10 hover:bg-sky-500 hover:text-white bg-slate-200 dark:hover:bg-sky-800 dark:text-slate-200 dark:hover:text-slate-200 dark:bg-slate-800 font-bold"
                      >
                        Send Order Via API
                      </button>
                      <table className="table-auto overflow-y-scroll">
                        <thead className="dark:text-white">
                          <tr>
                            <th
                              scope="col"
                              class="py-10 px-6 text-xs font-bold tracking-wider text-left uppercase "
                            >
                              PART NO.
                            </th>
                            <th
                              scope="col"
                              class="py-10 px-6 text-xs font-bold tracking-wider text-left  uppercase "
                            >
                              PART NAME
                            </th>
                            <th
                              scope="col"
                              class="py-10 px-6 text-xs font-bold tracking-wider text-left  uppercase "
                            >
                              QTY
                            </th>
                            <th
                              scope="col"
                              class="py-10 px-6 text-xs font-bold tracking-wider text-left  uppercase "
                            >
                              DELIVERY DATE
                            </th>
                            <th
                              scope="col"
                              class="py-10 px-6 text-xs font-bold tracking-wider text-left  uppercase "
                            >
                              TIME DELIVERED
                            </th>
                            <th
                              scope="col"
                              class="py-10 px-6 text-xs font-bold tracking-wider text-left  uppercase "
                            >
                              TYPE
                            </th>
                            <th
                              scope="col"
                              class="py-10 px-6 text-xs font-bold tracking-wider text-left  uppercase "
                            >
                              SUPPLIER NAME
                            </th>

                            {/* <th
                            scope="col"
                            class="py-10 px-6 text-xs font-bold tracking-wider text-left  uppercase "
                          >
                            <span class="py-10 px-6 text-xs font-bold tracking-wider text-left  uppercase ">
                              Action
                            </span>
                          </th> */}
                          </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200 dark:bg-slate-700 dark:divide-slate-500 transition-colors duration-300 transform">
                          {listPart.map((item, index) => (
                            <tr class="hover:bg-gray-100 text-gray-900 hover:text-gray-900 dark:hover:bg-slate-400 dark:text-slate-100">
                              <td class="py-4 px-6 text-sm font-medium ">
                                {item.part.code}
                              </td>
                              <td class="py-4 px-6 text-sm font-medium ">
                                {item.part.name}
                              </td>
                              <td class="py-4 px-6 text-sm font-medium ">
                                {item.part.totalQty}
                              </td>

                              <td class="py-4 px-6 text-sm font-medium ">
                                <div className="rounded-3xl border-2 py-2 text-center">
                                  {moment(item.delivery_time).format(
                                    "DD MMM yy"
                                  )}
                                </div>
                              </td>
                              <td class="py-4 px-6 text-sm font-medium whitespace-nowrap">
                                <div className="rounded-3xl border-2 py-2 text-center">
                                  {moment(item.delivery_time).format("hh:mm")}
                                </div>
                              </td>
                              <td
                                class={
                                  "py-4 px-6 text-sm font-medium whitespace-nowrap " +
                                  sourceColorChanger(item.part.source)
                                }
                              >
                                {sourceChanger(item.part.source)}
                              </td>
                              <td class="py-4 px-6 text-sm font-medium whitespace-nowrap">
                                {item.part.supplier.name}
                              </td>
                              {/* <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                              <button className="rounded-3xl py-2 text-center w-10/12 hover:bg-sky-500 hover:text-white bg-gray-200">
                                Send
                              </button>
                            </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid place-content-center mt-6">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={countPages}
                  onPageChange={(pageSelected) => {
                    setPageNumber(pageSelected.selected + 1);
                  }}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 w-full">
          <Footer />
        </div>
        <VideoModal video={"/videos/video-2.mp4"} />
      </div>
    </>
  );
}
