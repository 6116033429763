import {
  ChevronCircleLeftFilled,
  WeatherMoon24Filled,
  WeatherSunny24Filled,
} from "@fluentui/react-icons";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Logo from "../../assets/img/pidi-logo.png";
import LogoWhite from "../../assets/img/pidi-logo-white.png";
import { clearStorages, getToken } from "../../utils/storage";
import LogoutModal from "../Modals/LogoutModal";

export default function NavbarDetail() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state) => state);
  const urlList = window.location.href.split("/");
  let isLogin = getToken();
  const [title, setTitle] = useState("");

  const handleLogout = () => {
    clearStorages();
    dispatch({ type: "set", isLogoutModal: true });
    setTimeout(() => {
      history.push("/auth/login");
    }, 2000);
  };

  const handleTheme = () => {
    dispatch({ type: "set", darkMode: !darkMode });
  };

  useEffect(() => {
    if (urlList[5] === "main" && urlList[6] !== undefined) {
      setTitle("Vehicle Part List Order Platform");
    } else if (urlList[5] === "main") {
      setTitle("Orders");
    }
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  return (
    <>
      <nav
        id="header"
        className="w-full z-30 top-10 py-2 px-4 bg-gray-50 dark:bg-slate-900 transition-colors duration-300 transform shadow-lg"
      >
        <div className="w-full flex items-center justify-end mt-0 px-6 py-2 ">
          <label
            htmlFor="menu-toggle"
            className="cursor-pointer md:hidden block"
          >
            <svg
              className="fill-current text-gray-600"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <title>menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
            </svg>
          </label>
          <div
            className="hidden md:flex md:items-center md:w-auto w-full order-3 md:order-1 "
            id="menu"
          >
            <div className="absolute left-4 lg:left-10 z-10 sh">
              <div
                className="cursor-pointer"
                onClick={() => history.push("/app/home")}
              >
                <ChevronCircleLeftFilled className="border-slate-500 text-4xl text-black dark:text-white dark:border-slate-200 transition-colors duration-300 transform hover:scale-105" />
              </div>
            </div>
            <div className="absolute left-[12%]">
              <img
                src={darkMode ? LogoWhite : Logo}
                className="w-40 "
                alt="..."
              />
            </div>
            <div className="absolute left-[42%]">
              <span className="text-black dark:text-white text-3xl">
                {title}
              </span>
            </div>
          </div>

          <div
            className="order-2 md:order-3 flex flex-wrap items-center justify-end mr-0 md:mr-4 "
            id="nav-content"
          >
            <div className="auth flex items-center w-full md:w-full">
              {isLogin && (
                <button className="justify-items-center min-w-max w-full px-6 py-3 text-sm tracking-wide text-gray-800 dark:text-slate-200 outline capitalize transition-colors duration-300 transform rounded-full">
                  <span>{"Welcome, " + localStorage.getItem("name")}</span>
                </button>
              )}
              <div className="pl-5">
                {isLogin ? (
                  <button
                    onClick={handleLogout}
                    className="justify-items-center w-full px-6 py-3 text-sm tracking-wide text-white dark:text-black capitalize transition-colors duration-300 transform hover:bg-red-600 bg-black dark:hover:bg-red-400 dark:bg-white rounded-full"
                  >
                    <span>Logout</span>
                  </button>
                ) : (
                  <div className="h-[40px]" />
                )}
              </div>
            </div>
          </div>
          <div className="pr-32">
            <div className="" onClick={handleTheme}>
              {darkMode ? (
                <WeatherSunny24Filled className="text-white cursor-pointer hover:scale-110" />
              ) : (
                <WeatherMoon24Filled className="text-slate-900 cursor-pointer hover:scale-110" />
              )}
            </div>
          </div>
        </div>
      </nav>
      <LogoutModal text={"Logout Success !"} />
    </>
  );
}
