import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

// import Navbar from "../components/Navbars/AuthNavbar.js";
// import Footer from "../components/Footers/Footer";

// views

import Login from "../views/auth/Login";
import Register from "../views/auth/Register";

export default function Auth() {
  return (
    <>
      {/* <Navbar transparent /> */}
      <main>
        <section className="relative w-full h-full max-h-screen">
          <div
            className=""
            // style={{
            //   backgroundImage:
            //     "url(" + require("assets/img/register_bg_2.png").default + ")",
            // }}
          ></div>
          <Switch>
            <Route path="/auth/login" exact component={Login} />
            <Route path="/auth/register" exact component={Register} />
            <Route path="/auth/register/:id" exact component={Register} />
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
        </section>
      </main>
          {/* <Footer /> */}
    </>
  );
}
