import React from "react";
import { Link, useHistory } from "react-router-dom";
import { ChevronCircleLeftFilled } from "@fluentui/react-icons";
import { checkOrderTrackingId } from "../../api";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import Footer from "../../components/Footers/Footer";
// import { getSpknumber } from "../../utils/spkNumber";

export default function OrderTrackingDetail() {
  const history = useHistory();
  // const [showMore, setShowMore] = React.useState(false);
  const idFromUrl = window.location.href.split("/")[6];
  const [orderDetail, setOrderDetail] = useState({});
  const [orderTracking, setOrderTracking] = useState([]);
  const checkOrderTracking = async () => {
    try {
      const dataFound = await checkOrderTrackingId(idFromUrl);
      console.log(dataFound);
      setOrderDetail(dataFound.data);
      let orderTrackings = dataFound.data.order_trackings ?? [];
      let statusTracking = "not completed";
      if (dataFound.data.redeem) {
        statusTracking = "completed";
      }
      orderTrackings.unshift({
        name: "Car Received",
        updatedAt: dataFound.data.updatedAt,
        status: statusTracking,
      });
      setOrderTracking(orderTrackings);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    checkOrderTracking();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <section className="bg-white">
        <div className="flex justify-center min-h-screen">
          <div className="flex items-center w-full max-w-3xl lg:w-2/5 lg:h-[1200px]">
            <div
              className="absolute sm:hidden lg:block lg:h-[38rem] top-0 lg:left-0 h-[24rem] w-full "
              style={{
                backgroundImage:
                  "url(" +
                  require("../../assets/img/bg-order-detail.svg").default +
                  ")",
              }}
            >
              <div className="py-7">
                <div className="absolute left-4 lg:left-10 z-10">
                  <div
                    className="cursor-pointer"
                    onClick={() => history.push("/app/home")}
                  >
                    <ChevronCircleLeftFilled className="text-4xl text-white hover:scale-105" />
                  </div>
                </div>
                <h1 className="xl:text-3xl pt-0.5 text-2xl min-w-max font-semibold text-center tracking-wider text-black opacity-75 capitalize">
                  Order Tracking
                </h1>
              </div>
              <div className="flex justify-center">
                {orderDetail.car_model_color?.image_online && (
                  <img
                    src={
                      orderDetail.car_model_color?.image_online
                        ? orderDetail.car_model_color?.image_online
                        : require("../../assets/img/fortuner-right-black.svg")
                            .default
                    }
                    // className="w-[475px] lg:h-2/3"
                    className={"text-align-center"}
                    alt="..."
                  />
                )}
              </div>
            </div>
            <div
              className={`absolute lg:relative top-1/3 lg:top-[${
                orderDetail.status === "Complete" ? "1/3" : "220px"
              }] w-full`}
            >
              <div
                className={`pl-8 pr-8 ${
                  orderDetail.status === "Complete"
                    ? "pt-0 lg:mt-0"
                    : "pt-8 lg:mt-32"
                } mx-auto lg:px-12`}
              >
                <div className="relative flex flex-col md:grid grid-cols-9 mx-auto p-2 text-blue-50">
                  {/* <!-- right --> */}
                  {orderTracking?.map((item, index) => {
                    return (
                      <>
                        {index === 0 ? (
                          <div className="lg:bg-blue-500 lg:col-start-1 lg:col-end-10 lg:rounded-xl">
                            <div className="flex md:contents bg-blue-500 rounded-xl">
                              <div className="col-start-5 lg:col-start-1 col-end-6 lg:col-end-2 mx-5 md:mx-auto relative">
                                <div className="h-full w-6 flex items-center justify-center">
                                  <div className="h-full w-1 pointer-events-none"></div>
                                </div>
                                <div
                                  className={`w-8 h-8 absolute top-1/2 lg:top-10 -mt-4 -ml-1 lg:ml-5 rounded-ful ${
                                    item.status === "completed"
                                      ? "bg-blue-500"
                                      : "bg-blue-500"
                                  }`}
                                >
                                  {item.status === "completed" && (
                                    <img
                                      src={
                                        require("../../assets/img/delivered-circle.svg")
                                          .default
                                      }
                                      className="w-full"
                                      alt="..."
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="col-start-6 lg:col-start-2 lg:ml-14 col-end-10 p-4 rounded-xl my-1 mr-auto">
                                <h3 className="font-medium text-base mb-1">
                                  {item.name}
                                </h3>
                                <p className="leading-tight text-sm text-justify">
                                  {item.status === "completed"
                                    ? moment(item.updatedAt).format(
                                        "DD MMM yy hh:mm"
                                      )
                                    : "Not completed"}
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="flex md:contents">
                            <div className="col-start-5 lg:col-start-1 col-end-6 lg:col-end-2 mx-5 md:mx-auto relative">
                              <div
                                className={`${
                                  index ===
                                  orderDetail.order_trackings.length - 1
                                    ? "h-1/2"
                                    : "h-full"
                                } w-6 flex items-center justify-center`}
                              >
                                <div
                                  className={`h-full w-0.5 ${
                                    item.status === "completed"
                                      ? "bg-blue-500"
                                      : "bg-gray-500"
                                  } opacity-40 lg:ml-3.5 pointer-events-none`}
                                ></div>
                              </div>
                              <div
                                className={`w-3 h-3 absolute top-1/2 -mt-1.5 ml-1.5 lg:ml-[0.8rem] rounded-full ${
                                  item.status === "completed"
                                    ? "bg-blue-500"
                                    : "bg-gray-500"
                                } shadow`}
                              ></div>
                            </div>
                            <div className="text-black col-start-6 lg:col-start-2 col-end-10 p-4 rounded-xl my-1 mr-auto">
                              <h3 className="font-medium text-base mb-1">
                                {item.name}
                              </h3>
                              <p className="leading-tight text-sm text-justify opacity-75">
                                {item.status === "completed"
                                  ? moment(item.updatedAt).format(
                                      "DD MMM yy hh:mm"
                                    )
                                  : "Not completed"}
                              </p>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}

                  {/* {showMore ? (
                    <>
                      <div className="flex md:contents">
                        <div className="col-start-5 lg:col-start-1 col-end-6 lg:col-end-2 mx-5 md:mx-auto relative">
                          <div className="h-full w-6 flex items-center justify-center">
                            <div className="h-full w-0.5 bg-blue-500 opacity-40 lg:ml-3.5 pointer-events-none"></div>
                          </div>
                          <div className="w-3 h-3 absolute top-1/2 -mt-1.5 ml-1.5 lg:ml-[0.8rem] rounded-full bg-blue-500 shadow"></div>
                        </div>
                        <div className="text-black col-start-6 lg:col-start-2 col-end-10 p-4 rounded-xl my-1 mr-auto">
                          <h3 className="font-medium text-base mb-1">
                            Delivered
                          </h3>
                          <p className="leading-tight text-sm text-justify opacity-75">
                            24 May 2022 · 17:45
                          </p>
                        </div>
                      </div>
                      <div className="flex md:contents">
                        <div className="col-start-5 lg:col-start-1 col-end-6 lg:col-end-2 mx-5 md:mx-auto relative">
                          <div className="h-full w-6 flex items-center justify-center">
                            <div className="h-full w-0.5 bg-blue-500 opacity-40 lg:ml-3.5 pointer-events-none"></div>
                          </div>
                          <div className="w-3 h-3 absolute top-1/2 -mt-1.5 ml-1.5 lg:ml-[0.8rem] rounded-full bg-blue-500 shadow"></div>
                        </div>
                        <div className="text-black col-start-6 lg:col-start-2 col-end-10 p-4 rounded-xl my-1 mr-auto">
                          <h3 className="font-medium text-base mb-1">
                            Product on Manufacture
                          </h3>
                          <p className="leading-tight text-sm text-justify opacity-75">
                            19 May 2022 · 07:29
                          </p>
                        </div>
                      </div>
                      <div className="flex md:contents">
                        <div className="col-start-5 lg:col-start-1 col-end-6 lg:col-end-2 mx-5 md:mx-auto relative">
                          <div className="h-full w-6 flex items-center justify-center">
                            <div className="h-full w-0.5 bg-blue-500 opacity-40 lg:ml-3.5 pointer-events-none"></div>
                          </div>
                          <div className="w-3 h-3 absolute top-1/2 -mt-1.5 ml-1.5 lg:ml-[0.8rem] rounded-full bg-blue-500 shadow"></div>
                        </div>
                        <div className="text-black col-start-6 lg:col-start-2 col-end-10 p-4 rounded-xl my-1 mr-auto">
                          <h3 className="font-medium text-base mb-1">
                            Processing Order
                          </h3>
                          <p className="leading-tight text-sm text-justify opacity-75">
                            18 May 2022 · 19:00
                          </p>
                        </div>
                      </div>
                      <div className="flex md:contents">
                        <div className="col-start-5 lg:col-start-1 col-end-6 lg:col-end-2 mx-5 md:mx-auto relative">
                          <div className="h-full w-6 flex items-center justify-center">
                            <div className="h-full w-0.5 bg-blue-500 opacity-40 lg:ml-3.5 pointer-events-none"></div>
                          </div>
                          <div className="w-3 h-3 absolute top-1/2 -mt-1.5 ml-1.5 lg:ml-[0.8rem] rounded-full bg-blue-500 shadow"></div>
                        </div>
                        <div className="text-black col-start-6 lg:col-start-2 col-end-10 p-4 rounded-xl my-1 mr-auto">
                          <h3 className="font-medium text-base mb-1">
                            Order Received
                          </h3>
                          <p className="leading-tight text-sm text-justify opacity-75">
                            18 May 2022 · 18:56
                          </p>
                        </div>
                      </div>
                    </>
                  ) : null} */}
                  {/* Show more */}
                  {/* <div className="flex md:contents">
                    <div className="col-start-5 lg:col-start-1 col-end-6 lg:col-end-2 ml-5 md:mx-auto relative">
                      <div className="h-1/2 w-6 flex items-center justify-center">
                        <div className="h-full w-0.5 bg-blue-500 opacity-40 lg:ml-3.5 pointer-events-none"></div>
                      </div>
                      <div className="w-3 h-3 absolute top-1/2 -mt-1.5 ml-1.5 lg:ml-[0.8rem] rounded-full bg-blue-500 shadow"></div>
                    </div>
                    <div className="text-blue-500 col-start-6 lg:col-start-2 col-end-10 rounded-xl mr-auto">
                      <h3
                        className="font-medium text-base ml-2 lg:ml-0 mb-1 cursor-pointer"
                        onClick={() => {
                          setShowMore(!showMore);
                        }}
                      >
                        {showMore ? (
                          <>See less updates</>
                        ) : (
                          <>See 3 more updates</>
                        )}
                      </h3>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="flex items-center justify-center px-4">
                <div className="rounded-3xl p-5 shadow-lg w-full bg-white">
                  <div className="flex w-full items-center justify-between border-b pb-3">
                    <div className="flex items-center space-x-3">
                      <div className="text-lg font-bold text-black opacity-50">
                        Details
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 mt-4 mb-6">
                    <div>
                      <label className="text-[13px] text-black opacity-75">
                        SPK Number
                      </label>
                      <div className="text-black text-base font-medium">
                        {/* {getSpknumber(orderDetail?.id ?? "")} */}
                        {orderDetail?.spk_number}
                      </div>
                    </div>
                    <div>
                      <label className="text-[13px] text-black opacity-75">
                        Types of Sales
                      </label>
                      <div className="text-black text-base font-medium">
                        {orderDetail.type_of_sales}
                      </div>
                    </div>
                    <div>
                      <label className="text-[13px] text-black opacity-75">
                        Customer Name
                      </label>
                      <div className="text-black text-base font-medium">
                        {orderDetail.user?.fullName}
                      </div>
                    </div>
                    <div>
                      <label className="text-[13px] text-black opacity-75">
                        SPC Dealer Number
                      </label>
                      <div className="text-black text-base font-medium">
                        {orderDetail.spc_dealer_number}
                      </div>
                    </div>
                    <div>
                      <label className="text-[13px] text-black opacity-75">
                        Dealer
                      </label>
                      <div className="text-black text-base font-medium">
                        {orderDetail.dealer}
                      </div>
                    </div>
                    <div>
                      <label className="text-[13px] text-black opacity-75">
                        City
                      </label>
                      <div className="text-black text-base font-medium">
                        {orderDetail.user?.location}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {orderDetail.status === "Complete" && (
                <div className="p-8 lg:mt-10 pb-20 mx-auto lg:px-12">
                  <div>
                    <Link to={"/order-tracking/generate/" + orderDetail.id}>
                      <button className="justify-items-center w-full px-6 py-3 text-[17px] tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-2xl">
                        <span>Generate QR</span>
                      </button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <div className="bottom-0 w-full pt-14 xs:hidden">
          <Footer />
        </div> */}
      </section>
    </>
  );
}
