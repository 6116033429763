import { useField } from "formik";
import Proptypes from 'prop-types'
import { noValue } from "../../utils/validateInput";

const CustomInput = ({ label, showLabel, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      {!noValue(label) && showLabel ?
        <label className='block mb-2 text-sm text-gray-600 pl-3'>
          {label}
        </label> : <></>
      }
      <input
        {...field}
        {...props}
        className={meta.touched && meta.error ? "peer block w-full px-5 py-3 mt-2 text-red-900 bg-red-50 rounded-2xl border-2 border-red-200 focus:border-red-400 focus:ring-red-400 focus:outline-none focus:ring focus:ring-opacity-40" :
          "peer block w-full px-5 py-3 mt-2 text-black placeholder-gray-400 bg-gray-100 rounded-2xl border border-gray-200 focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"}
      />
      {meta.touched && meta.error && <div className="mt-2 text-red-700 text-sm">{meta.error}</div>}
    </>
  );
};
export default CustomInput;


CustomInput.defaultProps = {
  label: '',
  showLabel: true,
};

// ** PropTypes
CustomInput.propTypes = {
  label: Proptypes.string,
  showLabel: Proptypes.bool,
}
