import React from "react";

export default function Footer() {
  return (
    <>
      <footer className="relative bg-blueGray-200 dark:bg-slate-900 pb-6">
        <hr className="my-6 border-blueGray-300" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full text-center">
            <div className="text-sm text-blueGray-500 dark:text-white font-semibold py-1">
              Software Displayed at This Showcase May Not Reflect Actual Details
              of Software Available in Public. This Software is For PIDI4.0
              TMMIN Showcase Use Only.
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
