import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

//validation use formik and yup
import { Form, Formik } from "formik";
import CustomInput from "../../components/custom/CustomInput";
import { LoginSchema } from "../../validation";

import Logo from "../../assets/img/pidi-logo.png";
import { postLogin } from "../../api";
import { setToken } from "../../utils/storage";
import { useDispatch } from "react-redux";
import SuccessModal from "../../components/Modals/SuccessModal";
import FailedModal from "../../components/Modals/FailedModal";

export default function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isRemember, setIsRemember] = useState(true);

  const onSubmit = async (values) => {
    console.log("ini value dari validasi", values);
    setIsLoading(true);

    postLogin(values)
      .then((res) => {
        console.log("post login data", res);
        setToken(res.data.token);
        localStorage.setItem("name", res.data.data.fullName);
        setIsLoading(false);
        dispatch({ type: "set", isSuccessModal: true });
        setTimeout(() => {
          history.push("/app/home");
        }, 2500);
      })
      .catch((err) => {
        // alert("user doesnt exist")
        dispatch({ type: "set", isFailedModal: true });
        history.push("/auth/register/" + values.email);
        // setTimeout(() => {
        // }, 2500);
        console.error(err);
      });
  };

  useEffect(() => {
    dispatch({ type: "set", isSuccessModal: false });
  }, [1]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <section className="bg-white">
        {/* <div className="absolute -top-96 -left-[-760px] w-full h-full bg-gradient-radial">
        </div> */}
        <div className="flex justify-center min-h-screen">
          <div
            className="hidden bg-cover lg:block lg:w-3/5 rounded-tl-none rounded-bl-none rounded-3xl"
            style={{
              backgroundImage:
                "url(" + require("../../assets/img/bg-login.svg").default + ")",
            }}
          ></div>
          <div className="flex items-center w-full max-w-3xl p-8 pb-28 xl:pb-40 mx-auto lg:px-12 lg:w-2/5">
            <div className="w-full">
              <div className="grid justify-items-center pb-20">
                <img src={Logo} className="w-56 " alt="..."></img>{" "}
              </div>
              <h1 className="xl:text-3xl text-2xl font-semibold text-center tracking-wider text-black capitalize">
                Welcome!
              </h1>
              <p className="mt-5 text-center mb-12 xl:mb-24 text-black">
                Please login to your account.
              </p>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  email: "",
                }}
                validationSchema={LoginSchema}
                onSubmit={onSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="mb-4">
                      <CustomInput
                        label="Email"
                        name="email"
                        type="email"
                        placeholder="Email"
                      />
                    </div>
                    <div className="text-center mb-5 xl:mb-7">
                      <label className="inline-flex items-center cursor-pointer min-h-min">
                        <input
                          checked={isRemember}
                          onChange={() => {
                            setIsRemember(!isRemember);
                          }}
                          id="customCheckLogin"
                          type="checkbox"
                          className="form-checkbox border-0 rounded text-gray-700 ml-1 w-4 h-4 ease-linear transition-all duration-150 cursor-pointer"
                        />
                        <span className="ml-2 text-xs font-normal text-gray-300">
                          Remember me
                        </span>
                      </label>
                    </div>
                    {isLoading ? (
                      <button
                        type="button"
                        className="grid grid-cols-3 w-full bg-black h-11 rounded-2xl text-sm text-white hover:bg-slate-500 hover:cursor-not-allowed duration-[500ms,800ms]"
                        disabled
                      >
                        <div className="grid-1 justify-self-end my-auto h-5 w-5 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
                        <div className="grid-2 my-auto -mx-1">
                          {" "}
                          Processing...{" "}
                        </div>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="justify-items-center w-full px-6 py-3 text-sm tracking-wide text-white capitalize transition-colors duration-300 transform bg-black rounded-2xl"
                      >
                        <span>Next</span>
                      </button>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <SuccessModal text={"Login Successful !"} />
        <FailedModal text={"Login failed, you need to register first !"} />
      </section>
    </>
  );
}
