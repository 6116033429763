import * as React from "react";

import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));
const Progress = (props) => {
  let {
    buttontext = "Loading...",
    className = "",
    isLoading = false,
    isbutton = false,
  } = props;
  isLoading = isbutton ? isbutton : isLoading;

  return (
    <>
      {" "}
      {isLoading ? (
        isbutton ? (
          <button className={className} color="primary" disabled {...props}>
            {/* <Spinner className='me-6' size='sm' /> */}
            <span>{buttontext}</span>
          </button>
        ) : (
          <>
            <div
              className="flex justify-center align-items-center w-full min-h-screen fixed bg-slate-100 mx-auto"
              style={{
                zIndex: "30",
                opacity: "0.75",
                top: "0",
                left: "0",
                right: "0",
              }}
            />
            <div
              className="flex justify-center align-items-center w-1/5 h-full  fixed mx-auto"
              style={{
                zIndex: "31",
                // opacity: "0.5",
                top: "0",
                left: "0",
                right: "0",
              }}
            >
              <div className="my-auto w-[45vw]">
                <BorderLinearProgress />
              </div>
            </div>
          </>
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default Progress;
