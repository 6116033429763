export const sourceChanger = (key) => {
  switch (key) {
    case 1:
      return "LOCAL OUTHOUSE";
    case 2:
      return "JSP (TMC)";
    case 3:
      return "LOCAL INHOUSE";
    case 4:
      return "MSP";

    default:
      return "NOT FOUND";
  }
};
export const sourceColorChanger = (key) => {
  switch (key) {
    case 1:
      return "text-red-400";
    case 2:
      return "text-blue-400";
    case 3:
      return "text-yellow-400";
    case 4:
      return "text-green-400";

    default:
      return "NOT FOUND";
  }
};
