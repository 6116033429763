import React, { useState, useEffect } from "react";

// custom component
import ArrowRight from "../../components/Icons";
import ArrowLeft from "../../components/Icons/ArrowLeft";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { getCarType } from "../../api";

import FortunerBlack from "../../assets/img/fortuner-right-black.svg";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

export default function CarSliders({ type }) {
  const dispatch = useDispatch();
  const history = useHistory();
  //fortuner
  const [current, setCurrent] = useState(0);
  // const [listMobil, setlistMobil] = useState([]);
  const [listCar, setlistCar] = useState([]);
  // const length = SliderData.length;
  const length = listCar.length;

  function getCarsData() {
    getCarType(`all?page=1&perpage=8`)
      .then((res) => {
        var tempList = [];
        tempList = res.data.data;
        console.log("list cars", tempList);
        setlistCar(tempList);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    getCarsData();
    console.log("lenght car", length);
    dispatch({ type: "set", isSuccessModal: false });
  }, [1]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log("skrng ini di sini", current);
  }, [current]);

  const nextSlide = () => {
    console.log("next current", current);
    setCurrent(current === length - 1 ? 0 : current + 1);
  };
  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(listCar) || length <= 0) {
    return null;
  }

  return (
    <div className="flex justify-center w-full mt-10 text-center flex-col">
      <span className="text-center text-4xl font-bold">
        Choose Your Vehicle
      </span>
      <div className="flex justify-center">
        <section className="slider w-5/6 h-[60vh] mb-5">
          <ArrowLeft
            className="absolute z-10 top-1/2 left-[44px] md:left-[8rem] lg:left-[10rem] xl:left-[14rem] pt-2 lg:pt-0 w-14 lg:w-16"
            clickAction={prevSlide}
          />
          <ArrowRight
            className="absolute z-10 top-1/2 right-[44px] md:right-[8rem] lg:right-[10rem] xl:right-[14rem] pt-2 lg:pt-0 w-14 lg:w-16"
            clickAction={nextSlide}
          />

          {listCar.map((slide, index) => {
            return (
              <div
                key={index}
                className={index !== current ? "slide-noactive" : "slide"}
              >
                {index === (current === 0 ? length - 1 : current - 1) && (
                  <img
                    src={
                      slide.images1 === "Not Exist"
                        ? FortunerBlack
                        : slide.images1
                    }
                    alt="car"
                    className="image-slider-noactive w-[450px]"
                  />
                )}
              </div>
            );
          })}
          {listCar.map((slide, index) => {
            return (
              <div
                key={index}
                className={index === current ? "slide-active" : "slide"}
              >
                {index === current && (
                  <>
                    {/* <h1 className="text-center font-semibold text-2xl">
                    {slide.name}
                  </h1> */}
                    <div
                      // to={`/detail/product/${slide.id}`}
                      // to={`/detail/product/custom/${slide.model}`}
                      onClick={() => {
                        dispatch({
                          type: "set",
                          carId: slide.id,
                        });
                        // checkOrderStatus(slide.model);
                        history.push(`/detail/product/${slide.model}`);
                      }}
                    >
                      <img
                        src={
                          slide.images1 === "Not Exist"
                            ? FortunerBlack
                            : slide.images1
                        }
                        alt="car"
                        className="transition-all image-slider bg-gradient-radial-green scale-125 w-[500px] hover:cursor-pointer "
                      />
                    </div>
                    <h2 className="text-center font-semibold mt-10 text-2xl">
                      {/* {slide.type} */}
                      {slide.name}
                    </h2>
                  </>
                )}
              </div>
            );
          })}
          {listCar.map((slide, index) => {
            return (
              <div
                key={index}
                className={index !== current ? "slide-noactive" : "slide"}
              >
                {index === (current === length - 1 ? 0 : current + 1) && (
                  <img
                    src={
                      slide.images1 === "Not Exist"
                        ? FortunerBlack
                        : slide.images1
                    }
                    alt="car"
                    className="image-slider-noactive w-[450px]"
                  />
                )}
              </div>
            );
          })}
        </section>
      </div>
    </div>
  );
}
