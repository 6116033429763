import React, { useEffect } from "react";
import Modal from ".";
import { useDispatch, useSelector } from "react-redux";
import Success from "../../assets/img/success.gif";

export default function LogoutModal({ text }) {
  const dispatch = useDispatch();
  const { isLogoutModal } = useSelector((state) => state);

  useEffect(() => {
    dispatch({ type: "set", isLogoutModal: false });
  }, [1]); // eslint-disable-line react-hooks/exhaustive-deps

  function closeModal() {
    dispatch({ type: "set", isLogoutModal: false });
  }

  return (
    <>
      {isLogoutModal ? (
        <Modal closeModal={closeModal}>
          <div className="relative py-8 mx-auto min-w-[35vh]">
            <div className="border-0 rounded-lg shadow-lg relative py-5 flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="grid justify-items-center">
                <img
                  alt="success-alert"
                  className="w-64 shadow-e3 h-auto rounded-lg"
                  src={Success}
                />
                <div className="h-12">{text}</div>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
}
