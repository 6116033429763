import React from "react";
import { useHistory } from "react-router-dom";
import { ChevronCircleLeftFilled } from "@fluentui/react-icons";
import { checkOrderTrackingId } from "../../api";
import { useState } from "react";
import { useEffect } from "react";
// import moment from "moment";
import QRCode from "react-qr-code";
import Footer from "../../components/Footers/Footer";
// import { getSpknumber } from "../../utils/spkNumber";

export default function GenerateQR() {
  const history = useHistory();
  // const [showMore, setShowMore] = React.useState(false);
  const idFromUrl = window.location.href.split("/")[6];
  const [orderDetail, setOrderDetail] = useState({});
  const checkOrderTracking = async () => {
    try {
      const dataFound = await checkOrderTrackingId(idFromUrl);
      console.log(dataFound);
      setOrderDetail(dataFound.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    checkOrderTracking();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <section className="bg-white">
        <div className="flex justify-center min-h-screen">
          <div className="flex items-center w-full max-w-3xl lg:w-2/5">
            <div
              className="absolute sm:hidden lg:block lg:h-[24rem] top-0 lg:left-0 h-[24rem] max-h-[24rem] w-full "
              style={{
                backgroundImage:
                  "url(" +
                  require("../../assets/img/bg-order-detail.svg").default +
                  ")",
              }}
            >
              <div className="py-7">
                <div className="absolute left-4 lg:left-10 z-10">
                  <div
                    className="cursor-pointer"
                    onClick={() => history.push("/app/home")}
                  >
                    <ChevronCircleLeftFilled className="text-4xl text-white hover:scale-105" />
                  </div>
                </div>
                <h1 className="xl:text-3xl 2xs:text-xl pt-0.5 text-2xl min-w-max font-semibold text-center tracking-wider text-black opacity-75 capitalize">
                  Your Car Order QR Code
                </h1>
                <div className="w-full mt-5">
                  <QRCode
                    size={220}
                    // style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={idFromUrl}
                    className="mx-auto"
                  />
                </div>
              </div>
            </div>
            <div className="absolute lg:relative xs:top-[45vh] 2xs:top-1/3 lg:top-[220px] w-full">
              <div className="pt-8 pl-8 pr-8 lg:mt-20 mx-auto lg:px-12">
                <div className="relative flex flex-col md:grid grid-cols-9 mx-auto p-2 text-blue-50"></div>
              </div>
              <div className="flex items-center justify-center px-4">
                <div className="rounded-3xl p-5 shadow-lg w-full bg-white">
                  <div className="flex w-full items-center justify-between border-b pb-3">
                    <div className="flex items-center space-x-3">
                      <div className="text-lg font-bold text-black opacity-50">
                        Details
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 mt-4 mb-6">
                    <div>
                      <label className="text-[13px] text-black opacity-75">
                        SPK Number
                      </label>
                      <div className="text-black text-base font-medium">
                        {/* {getSpknumber(orderDetail?.id ?? "")} */}
                        {orderDetail?.spk_number}
                      </div>
                    </div>
                    <div>
                      <label className="text-[13px] text-black opacity-75">
                        Types of Sales
                      </label>
                      <div className="text-black text-base font-medium">
                        {orderDetail.type_of_sales}
                      </div>
                    </div>
                    <div>
                      <label className="text-[13px] text-black opacity-75">
                        Customer Name
                      </label>
                      <div className="text-black text-base font-medium">
                        {orderDetail.user?.fullName}
                      </div>
                    </div>
                    <div>
                      <label className="text-[13px] text-black opacity-75">
                        SPC Dealer Number
                      </label>
                      <div className="text-black text-base font-medium">
                        {orderDetail.spc_dealer_number}
                      </div>
                    </div>
                    <div>
                      <label className="text-[13px] text-black opacity-75">
                        Dealer
                      </label>
                      <div className="text-black text-base font-medium">
                        {orderDetail.dealer}
                      </div>
                    </div>
                    <div>
                      <label className="text-[13px] text-black opacity-75">
                        City
                      </label>
                      <div className="text-black text-base font-medium">
                        {orderDetail.user?.location}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-8 lg:mt-10 pb-28 xl:pb-40 mx-auto lg:px-12 mb-20">
                <div>
                  {/* <Link to={"/order-tracking/generate/" + orderDetail.id}>
                    <button className="justify-items-center w-full px-6 py-3 text-[17px] tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-2xl">
                      <span>Download</span>
                    </button>
                  </Link> */}
                  {/* <div className="pt-2">
                    <Link to={"/order-tracking/78201"}>
                      <button className="justify-items-center w-full px-6 py-3 text-[17px] tracking-wide text-black capitalize transition-colors duration-300 transform bg-gray-500 rounded-2xl">
                        <span className="opacity-60">Reach Us</span>
                      </button>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-0 w-full">
          <Footer />
        </div>
      </section>
    </>
  );
}
