import React, { useEffect } from "react";
import Modal from ".";
import { useDispatch, useSelector } from "react-redux";
import Failed from "../../assets/img/failed.gif";
import { Dismiss24Filled } from "@fluentui/react-icons";

export default function FailedModal({ text }) {
  const dispatch = useDispatch();
  const { isFailedModal } = useSelector((state) => state);

  useEffect(() => {
    dispatch({ type: "set", isFailedModal: false });
  }, [1]); // eslint-disable-line react-hooks/exhaustive-deps

  function closeModal() {
    dispatch({ type: "set", isFailedModal: false });
  }

  return (
    <>
      {isFailedModal ? (
        <Modal closeModal={closeModal}>
          <div className="relative py-8 mx-auto min-w-[35vh]">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex flex-row-reverse pr-10 pt-6">
                <div
                  className="cursor-pointer hover:scale-105"
                  onClick={() =>
                    dispatch({ type: "set", isFailedModal: false })
                  }
                >
                  <Dismiss24Filled color="#000" />
                </div>
              </div>
              <div className="grid justify-items-center py-5">
                <img
                  alt="success-alert"
                  className="w-64 shadow-e3 h-auto rounded-lg"
                  src={Failed}
                />
                <div className="h-12 px-10">{text}</div>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
}
