
import React from 'react';
import PropTypes from 'prop-types';
import ArrowCircleLeft from "../../assets/img/arrow-circle-left.svg"

export default function ArrowLeft(props) {
    const { className, clickAction } = props;

    return (
        <>
            <div onClick={clickAction}>
                <img
                    src={ArrowCircleLeft}
                    className={`cursor-pointer hover:scale-105 ${className}`}
                    alt="arrow-left"
                ></img>{" "}
            </div>
        </>
    );
}

ArrowLeft.defaultProps = {
    className: '',
    clickAction: () => { },
};

ArrowLeft.propTypes = {
    className: PropTypes.string,
    clickAction: PropTypes.func
};
