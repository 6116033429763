/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { checkOrder, getCar, postOrder } from "../../api";
import { changeColorFormat } from "../../store";
// import moment from "moment";
import ValidationModal from "../../components/Modals/ValidationModal";
import SuccessModal from "../../components/Modals/SuccessModal";
import ScrollBar from "react-perfect-scrollbar";
import ArrowLeft from "../../components/Icons/ArrowLeft";
import ArrowRight from "../../components/Icons";
import { clearStorages } from "../../utils/storage";
import Loader from "../../components/loader";

import FailedModal from "../../components/Modals/FailedModal";
import Progress from "../../components/Progress";
import io from "socket.io-client";

export default function ProductCustom() {
  const history = useHistory();
  const dispatch = useDispatch();
  const idFromUrl = window.location.href.split("/")[7];

  const [carList, setCarList] = useState([]);
  const [selectedCar, setSelectedCar] = useState(0);
  const [listAccessories, setlistAccessories] = useState([]);
  const [selectedColorCar, setSelectedColorCar] = useState({});

  const [totalPrice, setTotalPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [isProgressPage, setIsProgressPage] = useState(false);

  const [carDetails, setCarDetails] = useState({});
  const [carColors, setCarColors] = useState([]);
  const [carAccessories, setAccessories] = useState([]);

  const [socketNeta, setSocketNeta] = useState(null);
  const [secondNeta, setSecondNeta] = useState(180);
  const ENDPOINTNeta = `http://172.16.0.21:38081`;

  useEffect(() => {
    const newSocket = io(ENDPOINTNeta, { withCredentials: false });
    setSocketNeta(newSocket);
    return () => newSocket.close();
  }, [setSocketNeta]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const seconds = secondNeta;
    const timer = setInterval(() => {
      if (secondNeta > 0) setSecondNeta(seconds - 1);
    }, 1000);

    if (typeof secondNeta !== "undefined" && secondNeta <= 0) {
      socketNeta.emit("disconnect_request");
    }

    return () => clearInterval(timer);
  }, [secondNeta]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (socketNeta) {
      socketNeta.on("connect", () => {
        console.log("web socket neta connected");
      });

      socketNeta.on("disconnect", (msg, cb) => {
        console.log("web socket neta connected");
      });

      // listening change on database
      socketNeta.on("order:update", (message) => {
        console.log("websocket neta order:update :", message);
      });
    }
  }, [socketNeta]); // eslint-disable-line react-hooks/exhaustive-deps

  function checkOrderStatus() {
    checkOrder("checkOrder/get")
      .then((res) => {
        console.log(res);
        if (res.data.message === "data found" && res.data.data) {
          dispatch({
            type: "set",
            isFailedModal: true,
            isValidationModal: false,
          });
          setTimeout(() => {
            dispatch({ type: "set", isFailedModal: false });
          }, 4000);
        } else {
          submitData();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const submitData = () => {
    setIsLoading(true);
    setIsProgressPage(true);
    dispatch({ type: "set", isValidationModal: false });
    let additional_accessories_string = carAccessories
      .map((item, index) => item.accessory.name)
      .join(",");

    // let dataPart = listParts.map((item, index) => {
    //   return {
    //     part_id: item.part_id,
    //     delivery_time: new Date(),
    //     status: "Not Send",
    //   };
    // });
    let data = {
      car_model_master_id: carDetails.id,
      user_id: "d4bd1215-a5de-44f3-9848-97e6fbbcea46",
      status: "Not Complete",
      price: totalPrice,
      car_model_color_id: selectedColorCar.id,
      additional_accessories: additional_accessories_string,
      type_of_sales: "Private Agency",
      spc_dealer_number: "562612132",
      dealer: "Auto 2000",
      dealer_location: "Jakarta Utara",
      // order_details: dataPart,
      order_trackings: [
        {
          name: "Order Created",
          sort_order: 1,
          status: "completed",
        },
        {
          name: "Order Processed",
          sort_order: 2,
          status: "not completed",
        },
        {
          name: "Car Manufactured",
          sort_order: 3,
          status: "not completed",
        },
        {
          name: "Car On Delivery",
          sort_order: 4,
          status: "not completed",
        },
      ],
    };
    console.log("data for post", data);
    if (selectedColorCar.id !== "") {
      postOrder(data)
        .then((res) => {
          console.log("post order data", res);
          setIsLoading(false);
          socketNeta.emit("led", "u");

          setIsProgressPage(false);
          dispatch({ type: "set", isSuccessModal: true });
          setTimeout(() => {
            clearStorages();
            dispatch({ type: "set", isSuccessModal: false });
            history.push(`/order-tracking/main`);
          }, 2000);
        })
        .catch((err) => {
          setIsLoading(false);
          setIsProgressPage(false);
          console.error(err);
        });
    } else {
      alert("Please choose car color !");
    }
  };
  const handleOnSubmit = () => {
    checkOrderStatus();
  };

  const closeModal = () => {
    dispatch({ type: "set", isValidationModal: false });
  };

  const changeCarType = async (carSelected) => {
    setSelectedColorCar({});

    setAccessories([]);
    const carDetail = await getCar(`${carSelected}`);
    console.log("change Type", carDetail.data);
    setIsLoadingPage(false);
    setCarDetails(carDetail.data);
    setCarColors(carDetail.data.car_model_colors);
    setlistAccessories(carDetail.data.car_model_accessories);
    // setListPart(carDetail.data.car_model_parts);
    setSelectedColorCar(carDetail.data.car_model_colors[0]);
    setTotalPrice(carDetail.data.price);
  };
  const getCars = async () => {
    try {
      setIsLoadingPage(true);
      const dataCars = await getCar(`all?page=1&perpage=5&model=${idFromUrl}`);
      console.log(dataCars.data.data);
      setCarList(dataCars.data.data);
      changeCarType(dataCars.data.data[selectedCar].id);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCars();
  }, []);

  useEffect(() => {
    let totalPrices = 0;
    carAccessories.forEach((item, index) => {
      totalPrices += item.accessory.price;
    });
    setTotalPrice(carDetails.price + totalPrices);
  }, [carAccessories]);

  return (
    <>
      <section className="bg-white">
        <div className="flex justify-center min-h-[80vh]">
          <div className="hidden bg-cover lg:block lg:w-[67%] max-w-full bg-gradient-radial">
            <div className="absolute z-10 lg:left-[5.5%] xl:left-[7%] top-[17%] lg:w-[34rem] xl:w-[52rem]">
              {selectedColorCar?.image && (
                <img
                  src={selectedColorCar.image}
                  className="w-full"
                  alt="..."
                />
              )}
            </div>
            <div className="absolute lg:left-[16.5%] xl:left-[20%] top-[4.5%] lg:w-[18rem] xl:w-[28rem]">
              <img
                src={require("../../assets/img/bg-product.svg").default}
                className="w-full"
                alt="..."
              />
            </div>
            <div className="absolute bottom-0 left-[18.5%] md:left-[18.5%] lg:left-[18.5%] xl:left-[18.5%] w-[31%]">
              <div className="justify-items-center bottom-0 w-full px-6 py-7 text-base tracking-wide text-gray-800 capitalize transition-colors duration-300 transform bg-gray-400 rounded-2xl">
                <div className="inline-flex items-center min-h-min w-full">
                  <span className="w-2/5 font-semibold text-center">
                    {carDetails?.code}
                  </span>
                  <span className="w-1/5 text-center">|</span>
                  <span className="w-2/5 text-center">
                    {/* {priceIDR(totalPrice ?? 0)} */}
                    {"Price following market condition"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center w-full max-w-3xl p-8 mx-auto lg:px-12 lg:w-1/3 ">
            <div className="w-full">
              <div className="flex flex-row items-center justify-center mb-16 ">
                <ArrowLeft
                  className="w-14"
                  clickAction={() => {
                    let dataIndex =
                      selectedCar > 0 ? selectedCar - 1 : carList.length - 1;
                    setSelectedCar(dataIndex);
                    changeCarType(carList[dataIndex].id);
                  }}
                />
                <h1 className="xl:text-4xl text-3xl font-semibold text-center tracking-wider text-black capitalize">
                  {carDetails?.type}
                </h1>
                <ArrowRight
                  className="w-14"
                  clickAction={() => {
                    let dataIndex =
                      selectedCar < carList.length - 1 ? selectedCar + 1 : 0;
                    setSelectedCar(dataIndex);
                    changeCarType(carList[dataIndex].id);
                  }}
                />
              </div>
              <div className="grid grid-cols-3 gap-0">
                <div className="text-center tracking-wider text-black capitalize pb-12">
                  <div className="xl:text-4xl text-2xl">
                    {carDetails?.engine}
                    <small className="text-xs">{carDetails?.engine_type}</small>
                  </div>
                  <div className="pt-5">Engine</div>
                </div>
                <div className="text-center tracking-wider text-black capitalize pb-12">
                  <div className="xl:text-4xl text-2xl">
                    {carDetails?.power}
                    <small className="text-xs">HP</small>
                  </div>
                  <div className="pt-5">Power</div>
                </div>
                <div className="text-center tracking-wider text-black capitalize pb-12">
                  <div className="xl:text-4xl text-2xl">
                    {carDetails?.torque}
                    <small className="text-xs">Nm</small>
                  </div>
                  <div className="pt-5">Torque</div>
                </div>
              </div>
              <div className="pb-6">
                <label className="text-xl font-semibold">Color Option</label>
                <div className="flex flex-row overflow-x-auto pt-4">
                  {carColors.map((item, index) => (
                    <div
                      key={index}
                      className="grid justify-items-center w-96 min-w-[9rem] pb-3"
                    >
                      <div className="text-gray-60 font-medium text-sm min-h-[2rem]">
                        {item.color.name}
                      </div>
                      <div
                        className={` grid justify-items-center border-b-8 rounded-md py-3 cursor-pointer ${
                          item.color.id === selectedColorCar?.color?.id
                            ? "border-blue-400"
                            : "border-gray-70"
                        }`}
                        onClick={(event) => {
                          setSelectedColorCar(item);
                        }}
                      >
                        <div
                          className="w-16 h-16 rounded-full"
                          style={{
                            background: changeColorFormat(item.color.code),
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="pb-14">
                <label className="text-xl font-semibold">Accessories</label>
                <div className="flex flex-col overflow-y-auto h-48 pt-4">
                  <ScrollBar>
                    {listAccessories.map((item, index) => (
                      <button
                        key={index}
                        className={`justify-items-center mb-3 font-semibold w-full px-6 py-2 text-base tracking-wide text-gray-80 capitalize transition-colors duration-300 transform bg-white ${
                          carAccessories.includes(item)
                            ? "border-blue-400"
                            : "border-gray-70"
                        } border-4 rounded-full hover:border-blue-400 hover:ring-blue-400 hover:outline-none hover:ring hover:ring-opacity-40`}
                        onClick={(event) => {
                          if (carAccessories.includes(item)) {
                            setAccessories(
                              carAccessories.filter((data) => data !== item)
                            );
                          } else {
                            setAccessories((prev) => [...prev, item]);
                          }
                        }}
                      >
                        + {item.accessory.name}
                      </button>
                    ))}
                  </ScrollBar>
                </div>
              </div>
              {isLoading ? (
                <button
                  type="button"
                  className="grid grid-cols-3 w-full bg-black py-5 rounded-full text-sm text-white hover:bg-slate-500 hover:cursor-not-allowed duration-[500ms,800ms]"
                  disabled
                >
                  <div className="grid-1 justify-self-end my-auto h-5 w-5 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
                  <div className="grid-2 my-auto -mx-1"> Processing... </div>
                </button>
              ) : (
                <button
                  onClick={() =>
                    dispatch({ type: "set", isValidationModal: true })
                  }
                  className="justify-items-center w-full px-6 py-5 text-base tracking-wide text-gray-800 capitalize transition-colors duration-300 transform bg-gray-400 rounded-full hover:bg-blue-200"
                >
                  <span className="font-semibold">Order Now</span>
                </button>
              )}
            </div>
          </div>
        </div>
        <ValidationModal
          text={
            "Are you sure to proceed? You can still make some changes before submitting your order"
          }
          approveButton={handleOnSubmit}
          cancelButton={closeModal}
        />
        <SuccessModal text={"Order allocation and allocation checked"} />
        <Loader isLoading={isLoadingPage} />
        <Progress isLoading={isProgressPage} />
        <FailedModal text={"Please finish your existing order first"} />
      </section>
    </>
  );
}
