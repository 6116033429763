import axios from "axios";
import CONFIG from "../config";
import { getToken } from "../utils/storage";

const fullURL = (path) => {
  return `${CONFIG.API_URL}/${path}`;
};

export const handleNetworkError = (error) => {
  if (error.message === "Network request failed") {
    alert(
      "Kesalahan Jaringan",
      "Silakan periksa koneksi Anda dan coba kembali.",
      "iconNoInet"
    );
  }
  throw error;
};

const post = (api) => (data, token) => {
  const TOKEN = getToken();
  return axios.post(fullURL(api), data, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "application/json",
      Authorization: `${TOKEN}`,
      // 'apikey': process.env.REACT_APP_API_KEY
    },
  });
};
const put = (api) => (data, id, token) => {
  const TOKEN = getToken();
  return axios.put(fullURL(api + id), data, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "application/json",
      Authorization: `${TOKEN}`,
      // 'apikey': process.env.REACT_APP_API_KEY
    },
  });
};

const get =
  (api) =>
  (param = "") => {
    const TOKEN = getToken();
    return axios(
      `${fullURL(api)}${param}`,
      {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json",
          Authorization: `${TOKEN}`,
          // 'apikey': process.env.REACT_APP_API_KEY
        },
      },
      { handleNetworkError }
    ).catch((err) => {
      console.log(err);
    });
  };

// const getWithSlug = (api) => (slug, token) => {
//   return axios(
//     `${fullURL(api)}${slug}`,
//     {
//       method: "GET",
//       headers: {
//         "Access-Control-Allow-Origin": "*",
//         "Content-type": "application/json",
//         Authorization: `Bearer ${token}`,
//         'apikey': process.env.REACT_APP_API_KEY
//       },
//     },
//     { handleNetworkError }
//   ).catch((err) => {});
// };

export const getListUser = get(`api/v1/user/all`);
export const getCar = get("api/v1/car/");
export const getBanner = get("api/v1/banner/");
export const getCarType = get("api/v1/car-type/");
export const postOrder = post("api/v1/order/create");
export const putOrder = put("api/v1/order/");
export const checkOrderTrackingId = get("api/v1/order/");
export const checkOrder = get("api/v1/order/");
export const checkpoint = post("api/v1/order/checkpoint");
export const getOrder = get("api/v1/order/");
export const getOrderDetail = get("api/v1/order-detail/");
export const postLogin = post("api/v1/auth/login");
export const postRegister = post("api/v1/auth/register");
// export const postTrigger = post("generatedata");

const API = {
  getListUser,
  getCar,
  getBanner,
  checkpoint,
  postOrder,
  checkOrderTrackingId,
  checkOrder,
  postRegister,
  getOrderDetail,
  putOrder,
};

export default API;
