import React, { useEffect, useState } from "react";
// custom component
// import ArrowRight from "../../components/Icons";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import CarSliders from "../../components/CarSliders/CarSliders";
import { getBanner } from "../../api";
import { noValue } from "../../utils/validateInput";

export default function Home() {
  const [listBanner, setListBanner] = useState([]);

  const getBannerData = async () => {
    try {
      const dataBanner = await getBanner(`all?page=1&perpage=5`);
      console.log(dataBanner.data.data);
      setListBanner(dataBanner.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBannerData();
  }, [1]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="w-full ">
        <div className="flex justify-center ">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            {!noValue(listBanner) && (
              <>
                {listBanner.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="w-full h-[90vh]">
                      <img
                        src={item.image}
                        loading="lazy"
                        alt="banner"
                        style={{
                          objectFit: "fill",
                        }}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </>
            )}
          </Swiper>
        </div>
        {/* <div className="flex justify-center h-[80vh]">
          <div className="flex items-center w-full max-w-3xl p-8 pb-24 xl:pb-24 mx-auto lg:px-12 lg:w-1/2">
            <div className="w-full">
              <h1 className="xl:text-5xl text-3xl font-semibold tracking-wider text-black capitalize">
                Find Out New Experience In Toyota PIDI Showcase
              </h1>
              <p className="mt-10 mb-12 xl:mb-24 text-black">
                Discover TMMIN production plan in PIDI 4.0 to Order and
                Customize a Vehicle, and find out how the production flow going
                on PT TMMIN
              </p>
              <div className="mb-5 xl:mb-7">
                <label className="inline-flex items-center cursor-pointer min-h-min">
                  <span className="underline underline-offset-4 text-gray-800 text-lg font-semibold">
                    Choose Vehicle
                  </span>
                  <ArrowRight className={"w-14"} />
                </label>
              </div>
            </div>
          </div>
          <div
            className="hidden bg-cover lg:block lg:w-5/12 rounded-3xl rounded-tr-none rounded-br-none"
            style={{
              backgroundImage:
                "url(" + require("../../assets/img/bg-home.svg").default + ")",
            }}
          >
            <div className="mt-40">
              <img
                src={
                  require("../../assets/img/fortuner-left-silver.svg").default
                }
                className="w-full"
                alt="..."
              />
            </div>
          </div>
        </div> */}
        <div
          className="h-[100vh] flex align-middle items-center"
          style={{
            backgroundImage: 'url("./bg-car.jpg")',
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            backgroundPosition: "center",
          }}
        >
          <CarSliders type={"fortuner"} />
        </div>

        {/* <Loader isLoading={isLoading} /> */}
      </div>
    </>
  );
}
