import React from "react";
import { Route, Redirect } from "react-router-dom";
import { clearStorages, getToken } from "../utils/storage";
import { noValue } from "../utils/validateInput";

const PrivateRoute = ({ component: Component, ...props }) => {
  const isAuthenticated = getToken();
  if (noValue(isAuthenticated)) {
    clearStorages();
  }

  return (
    <Route
      {...props}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/auth/login" />
      }
    />
  );
};

export default PrivateRoute;