import * as yup from "yup";

export const LoginSchema = yup.object().shape({
  email: yup.string().email("Please enter a valid email").required(),
});
export const RegisterSchema = yup.object().shape({
  fullName: yup.string().required("Full Name is required field"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email is required field"),
  company: yup.string().required("Company is required field"),
  location: yup.string().required("Location is required field"),
});
