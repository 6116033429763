import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// import Footer from "../components/Footers/Footer";
import OrderDashboard from "../views/detail/OrderDashboard";
import OrderDetail from "../views/detail/OrderDetail";
import GenerateQR from "../views/main/GenerateQR";
import OrderCheckpoint from "../views/main/OrderCheckpoint";

// components
// import Navbar from "../components/Navbars";

import OrderTracking from "../views/main/OrderTracking";
import OrderTrackingDetail from "../views/main/OrderTrackingDetail";

export default function OrderTrackingLayout() {
  return (
    <>
      <div className={`bg-gray-50 dark:bg-slate-900 transition-colors duration-300 transform`}>
        {/* <Navbar /> */}
        <main>
          <section className="relative w-full h-full">
            {/* <div
              className="top-0 w-full h-full bg-no-repeat bg-full"
            ></div> */}
            <Switch>
              <Route
                path="/order-tracking/main"
                exact
                component={OrderDashboard}
              />
              <Route
                path="/order-tracking/main/:id"
                exact
                component={OrderDetail}
              />
              <Route path="/order-tracking" exact component={OrderTracking} />
              <Route
                path="/order-tracking/checkpoint/:id"
                exact
                component={OrderCheckpoint}
              />
              <Route
                path="/order-tracking/detail/:id"
                exact
                component={OrderTrackingDetail}
              />
              <Route
                path="/order-tracking/generate/:id"
                exact
                component={GenerateQR}
              />
              <Redirect from="/order-tracking" to="/order-tracking" />
            </Switch>
          </section>
        </main>
        {/* <Footer /> */}
      </div>
    </>
  );
}
