import React from "react";
import PropTypes from "prop-types";
import ArrowCircleRight from "../../assets/img/arrow-circle-right.svg";

export default function ArrowRight(props) {
  const { className, clickAction } = props;

  return (
    <>
      <div onClick={clickAction}>
        <img
          src={ArrowCircleRight}
          className={`cursor-pointer hover:scale-105 ${className}`}
          alt="arrow-right"
        ></img>{" "}
      </div>
    </>
  );
}

ArrowRight.defaultProps = {
  className: "",
  clickAction: () => {},
};

ArrowRight.propTypes = {
  className: PropTypes.string,
  clickAction: PropTypes.func,
};
