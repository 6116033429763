import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { checkOrderTrackingId } from "../../api";
import Footer from "../../components/Footers/Footer";
import FailedModal from "../../components/Modals/FailedModal";
import SuccessModal from "../../components/Modals/SuccessModal";

export default function OrderTracking() {
  const [orderId, setOrderId] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const checkOrderTracking = async (e) => {
    e.preventDefault();
    try {
      const dataFound = await checkOrderTrackingId(orderId);
      console.log(dataFound);
      if (dataFound.data !== null) {
        dispatch({ type: "set", isSuccessModal: true });
        setTimeout(() => {
          dispatch({ type: "set", isSuccessModal: false });
          history.push("/order-tracking/detail/" + dataFound.data.id);
        }, 4000);
      } else {
        dispatch({ type: "set", isFailedModal: true });
        setTimeout(() => {
          dispatch({ type: "set", isFailedModal: false });
        }, 4000);
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "set", isFailedModal: true });
      setTimeout(() => {
        dispatch({ type: "set", isFailedModal: false });
      }, 4000);
    }
  };
  return (
    <>
      <section className="bg-white">
        <div className="flex justify-center min-h-screen">
          <div className="flex items-center w-full max-w-3xl lg:w-2/5">
            <div
              className="absolute sm:hidden lg:block lg:h-[18rem] top-0 lg:left-0 h-[18rem] max-h-[18rem] w-full bg-cover bg-no-repeat bg-center rounded-br-3xl rounded-bl-3xl"
              style={{
                backgroundImage:
                  "url(" +
                  require("../../assets/img/bg-order.svg").default +
                  ")",
              }}
            />
            <div className="w-full">
              <div className="p-8 mt-40 lg:mt-24 pb-28 xl:pb-40 mx-auto lg:px-12">
                <h1 className="xl:text-3xl text-2xl font-semibold text-center tracking-wider text-black capitalize">
                  Order Tracking
                </h1>
                <p className="mt-5 text-center mb-12 xl:mb-24 text-black">
                  Please enter your Booking Number
                </p>
                <form>
                  <div className="mb-4">
                    <input
                      onChange={(event) => {
                        setOrderId(event.target.value);
                      }}
                      type="text"
                      placeholder="Booking Number"
                      className="peer block w-full px-5 py-3 mt-2 text-black placeholder-gray-400 bg-gray-100 rounded-2xl border border-gray-200 focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    <p className="mt-2 invisible peer-invalid:visible text-red-600 text-sm">
                      Please provide a valid email address.
                    </p>
                  </div>

                  <button
                    onClick={checkOrderTracking}
                    className="justify-items-center w-full px-6 py-3 text-sm tracking-wide text-white capitalize transition-colors duration-300 transform bg-black rounded-2xl"
                  >
                    <span>Next</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 w-full">
          <Footer />
        </div>
        <SuccessModal text={"Data Found !"} />
        <FailedModal text={"Data Not Found !"} />
      </section>
    </>
  );
}
