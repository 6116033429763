import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: "Monthly Production Plan",
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: false,
    },
    y: {
      stacked: false,
    },
  },
};

const labels = ["Fortuner", "Yaris", "Sienta", "Vios", "Innova", "Corolla"];

export const data = {
  labels,
  datasets: [
    {
      label: "xxx",
      // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      data: [555, 555, 555, 555, 555, 555],
      backgroundColor: "rgb(255, 99, 132)",
    },
    {
      label: "xxx",
      data: [555, 555, 555, 555, 555, 555],
      backgroundColor: "rgb(75, 192, 192)",
    },
    {
      label: "xxx",
      data: [555, 555, 555, 555, 555, 555],
      backgroundColor: "rgb(53, 162, 235)",
    },
  ],
};
export const options2 = {
  plugins: {
    title: {
      display: true,
      text: "Annual Production Plan",
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels2 = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
];

export const data2 = {
  labels: labels2,
  datasets: [
    {
      label: "xxx",
      // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      data: [555, 555, 555, 555, 555, 555, 555],
      backgroundColor: "rgb(255, 99, 132)",
    },
    {
      label: "xxx",
      data: [555, 555, 555, 555, 555, 555, 555],
      backgroundColor: "rgb(75, 192, 192)",
    },
    {
      label: "xxx",
      data: [555, 555, 555, 555, 555, 555, 555],
      backgroundColor: "rgb(53, 162, 235)",
    },
  ],
};
export default function ProductionPlan() {
  return (
    <>
      <section className="bg-gray-50 py-10 px-10">
        <div className="flex flex-col justify-center w-full">
          <div className="bg-white shadow-md px-10 py-10 rounded-xl w-full mb-10 ">
            <div className="flex flex-row ">
              <Bar options={options} data={data} />
            </div>
          </div>
          <div className="flex flex-row ">
            <div className="bg-white shadow-md px-10 py-10 rounded-xl w-full mr-10">
              <div className="flex flex-row">
                <Bar options={options2} data={data2} />
              </div>
            </div>
            <div className="bg-white shadow-md px-10 py-10 rounded-xl w-full">
              <div className="flex flex-row"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
