import React from "react";
import { Switch, Route } from "react-router-dom";
import Footer from "../components/Footers/Footer";

// components
import NavbarDetail from "../components/NavbarDetail";
import OrderDashboard from "../views/detail/OrderDashboard";

import OrderDetail from "../views/detail/OrderDetail";
import Product from "../views/main/Product";
import ProductCustom from "../views/main/ProductCustom";

export default function Detail() {
  return (
    <>
      <div className="bg-gray-50">
        <NavbarDetail />
        <main>
          <section className="relative w-full h-full">
            <div className="top-0 w-full h-full bg-no-repeat bg-full"></div>
            <Switch>
              <Route path="/detail/product/:id" exact component={Product} />
              <Route
                path="/detail/product/custom/:id"
                exact
                component={ProductCustom}
              />
              <Route path="/detail/order/:id" exact component={OrderDetail} />
              <Route
                path="/detail/order-main"
                exact
                component={OrderDashboard}
              />
              {/* <Redirect from="/" to="/app/home" /> */}
            </Switch>
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
}
