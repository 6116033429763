import React, { useEffect } from "react";
// import { useDispatch } from "react-redux";
import { Route, Switch, Redirect, HashRouter } from "react-router-dom";
import PrivateRoute from "./helper/PrivateRoute.js";

import Auth from "./layouts/Auth.js";
import Detail from "./layouts/Detail.js";
import Main from "./layouts/Main.js";
import OrderTrackingLayout from "./layouts/OrderTracking.js";

export default function App() {
  // const dispatch = useDispatch();

  useEffect(() => {
    // if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    //   dispatch({ type: "set", darkMode: true });
    // } else {
    //   dispatch({ type: "set", darkMode: false });
    // }
    // localStorage.theme = 'light'

    // localStorage.theme = 'dark'

    // localStorage.removeItem('theme')
  }, [])

  return (
    <HashRouter>
      <Switch>
        {/* add routes with layouts */}
        <PrivateRoute path="/app" component={Main} />
        <PrivateRoute path="/detail" component={Detail} />
        <Route path="/auth" component={Auth} />
        <Route path="/order-tracking" component={OrderTrackingLayout} />
        {/* add redirect for first page */}
        <Redirect from="*" to="/auth" />
      </Switch>
    </HashRouter>
  );
}
