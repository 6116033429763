import React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Logo from "../../assets/img/pidi-logo.png";
import { clearStorages } from "../../utils/storage";
import SuccessModal from "../Modals/SuccessModal";

export default function Navbar() {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleLogout = () => {
    clearStorages();
    dispatch({ type: "set", isSuccessModal: true });
    setTimeout(() => {
      history.push("/auth/login");
    }, 2000);
  };
  return (
    <>
      <nav
        id="header"
        className="w-full z-30 top-10 py-2 px-4 bg-gray-50 shadow-lg"
      >
        <div className="w-full flex items-center justify-between mt-0 px-6 py-2">
          <label
            htmlFor="menu-toggle"
            className="cursor-pointer md:hidden block"
          >
            <svg
              className="fill-current text-gray-600"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <title>menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
            </svg>
          </label>
          <div
            className="hidden md:flex md:items-center md:w-auto w-full order-3 md:order-1"
            id="menu"
          >
            <div className="">
              <img src={Logo} className="w-40 " alt="..."></img>
            </div>
            <nav>
              <ul className="md:flex items-center justify-between text-base text-gray-600 pl-20 pt-4 md:pt-0">
                <li>
                  <Link to={"/app/home"}>
                    <button className="inline-block no-underline font-medium text-lg justify-items-center w-full px-8 py-2 tracking-wide text-gray-800 capitalize transition-colors duration-300 transform hover:bg-gray-100 bg-none rounded-full focus:outline-none focus:bg-gray-100">
                      <span>Home</span>
                    </button>
                  </Link>
                </li>
                {/* <li className="ml-12">
                  <button className="inline-block no-underline font-medium text-lg justify-items-center w-full px-8 py-2 tracking-wide text-gray-800 capitalize transition-colors duration-300 transform hover:bg-gray-100 bg-none rounded-full focus:outline-none focus:bg-gray-100">
                    <span>Vehicle</span>
                  </button>
                </li>
                <li className="ml-12">
                  <Link to={"/app/production-plan"}>
                    <button className="inline-block no-underline min-w-max font-medium text-lg justify-items-center w-full px-8 py-2 tracking-wide text-gray-800 capitalize transition-colors duration-300 transform hover:bg-gray-100 bg-none rounded-full focus:outline-none focus:bg-gray-100">
                      <span>Production Plan</span>
                    </button>
                  </Link>
                </li> */}
              </ul>
            </nav>
          </div>
          <div
            className="order-2 md:order-3 flex flex-wrap items-center justify-end mr-0 md:mr-4"
            id="nav-content"
          >
            <div className="auth flex items-center w-full md:w-full">
              <button className="justify-items-center min-w-max w-full px-6 py-3 text-sm tracking-wide text-gray-800 outline capitalize transition-colors duration-300 transform rounded-full">
                <span>{"Welcome, " + localStorage.getItem("name")}</span>
              </button>
              <div className="pl-5">
                <button
                  onClick={handleLogout}
                  className="justify-items-center w-full px-6 py-3 text-sm tracking-wide text-white capitalize transition-colors duration-300 transform hover:bg-red-600 bg-black rounded-full"
                >
                  <span>Logout</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <SuccessModal text={"Logout Successful !"} />
    </>
  );
}
