import { createStore } from "redux";
import moment from "moment";
import momentID from "moment/locale/id";

const initialState = {
  //global
  carId: "",
  darkMode: false,
  // Modals
  isProductModal: false,
  isSuccessModal: false,
  isFailedModal: false,
  isValidationModal: false,

  // Product
  isExterior: false,
  isInterior: false,
  isBeyond: false,
  carDetails: null,
  carModels: [],
  carColors: [],
  carParts: [],
  carAccessories: [],
};


export const GMT = moment().format("Z").replace(/[0:]/g, "");
export const momentLocale = moment().locale("id", momentID);

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};

export const truncate = (input) => {
  if (input.length > 45) {
    return input.substring(0, 45) + "...";
  }
  return input;
};

export const priceIDR = (num) => {
  return num.toLocaleString('id', { style: 'currency', currency: 'IDR' })
};

export const changeColorFormat = (style) => {
  let item = [];

  item = style.split(":")

  let splitData = item[1].split(";")
  // console.log(splitData[0])
  return splitData[0]
}



export const displayWindowSize = (types) => {
  var val = 0;
  if (types.toLowerCase() === "width") {
    val = document.documentElement.clientWidth;
  } else if (types.toLowerCase() === "height") {
    val = document.documentElement.clientHeight;
  }
  return val;
};

const store = createStore(
  changeState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
export default store;
